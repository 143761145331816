import React, { Fragment, useState, useEffect, useRef, Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image,
  Accordion,
  FormControl,
  Label
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal, LS_MSG_TAG } from "../config/Config.js";
import axios from "axios";

//import { saveAs } from 'file-saver'

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillXCircleFill,
  BsFillPenFill,
  BsCardText,
  BsFiletypeDocx,
  BsFilePdfFill,
  BsDownload,
  BsCardImage
} from "react-icons/bs";
import SidebarMain from "./SidebarMain.js";
import {formatDateIndian, getDistrictList, getStateList, getCustomerMessages } from "./utils.js";
import spinner_logo from '../assets/images/spinner_logo.png';
//import { toHaveFormValues } from "@testing-library/jest-dom/matchers";



//function AddUpdateCustomer () {
const AddUpdateStudy = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  
  let initialItems = [];
  const intialValues = {
   name: "",
   mobile: "",
   cs_id: 0,
   bill_amt: 0.00,
   total_amt: 0.00,
   discount_amt: 0.00,
   study_date_field: formatDate(new Date()), 
   //name: "",
   age: 0,
   gender: "",
   mobile_primary: "",
   mobile_secondary: "",
  };

  /*
  const optionsDoctor = [
    { value: 1, label: "Nikil Vishnu(PSG)" },
    { value: 2, label: "Kumar Ram(CNS)" },
    { value: 3, label: "Karthick Raja(ABC)" },
    { value: 4, label: "Anu Krishnan(KMCH)" },
    { value: 3, label: "Sukumar Kanna(SNS)" },
  ];
  const optionsStudy = [
    { value: 1, label: "Ultra Scan" },
    { value: 2, label: "Abdomen Scan" },
    { value: 3, label: "Neck Scan" },
  ];
  */
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [itemsOrig, setItemsOrig] = useState([]);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(initialItems);
  const [docNumberInfo, setDocNumberInfo] = useState({});

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [alertPurpose, setAlertPurpose] = useState('init');

  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  

  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertTitleDialog, setAlertTitleDialog] = useState({});
  const [alertBodyDialog, setAlertBodyDialog] = useState({});
  const [submitAction, setSubmitAction] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checkState, setCheckState] =  useState(false);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [dispatchList, setDispatchList] = useState([]);
  const [historyDispatchList, setHistoryDispatchList] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [selectedRadio, setSelectedRadio] = useState(-1);
  const [radioSelect, setRadioSelect] = useState({});
  const [isEditable, setIsEditable] =  useState(false);
  const [statusState, setStatusState] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [editAction, setEditAction] = useState("amount_edit");
  const [studyIdSelect, setStudyIdSelect] = useState({});
  const [optPhysician, setOptPhysician] = useState([]);
  const [optStudy, setOptStudy] = useState([]);
  const [optStudyOrig, setOptStudyOrig] = useState([]);
  const [physicianIdSelect, setPhysicianIdSelect] = useState({});
  const [optType, setOptType] = useState([]);
  const [typeIdSelect, setTypeIdSelect] = useState({});
  //const [searched, setSearched] = useState(false);
  const [showSearchItems, setShowSearchItems] = useState(false);
  const [showAddItems, setShowAddItems] = useState(true);
  const [patientSelected, setPatientSelected] = useState(false);
  const[optGender, setOptGender] = useState([]);
  const [genderIdSelect, setGenderIdSelect] = useState({});
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const currPatientIdRef = useRef();
  const submitActionRef = useRef("save");

  var validateErr = false;
  const [curSelRowRadio, setCurSelRowRadio] = useState(-1);

  const [file1, setFile1] = useState();
  const selectedDocsRef = useRef([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [selectedPatientRow, setSelectedPatientRow] = useState(-1);

  const study_no_ref = useRef("CS/27");
  const study_sno_ref = useRef(27);

  const validateSearch = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name && !values.mobile) {
      errors.name = "Please enter valid Details!";
      validateErr = true;
    }
    else {
      errors.name = "";
    }
  
  
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_PATIENT_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }

    if (!values.age) {
      errors.age = "Please enter Age";
      validateErr = true;
      //setValidateError(true);
    }

    if (!values.mobile_primary) {
      errors.mobile_primary = getCustomerMessages(LS_MSG_TAG,"MSG_MOBILE_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    
    if (values.mobile_primary && values.mobile_primary.length != 10) {
      errors.mobile_primary =  getCustomerMessages(LS_MSG_TAG,"MSG_MOBILE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }

    if (values.mobile_secondary && values.mobile_secondary.length != 10) {
      errors.mobile_secondary =  getCustomerMessages(LS_MSG_TAG,"MSG_MOBILE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }

    /*
    errors.received_amt_study = "";
    errors.mobileno_disp = "";
    errors.receivedby_disp = "";
    
    if ( editAction === 'amount_edit'){
    if (!values.received_amt_study && values.received_amt_study === 0) {
      errors.received_amt_study = "Received Amount is required!";
      validateErr = true;
    }
    else {
      errors.received_amt_study = "";
    }
  }
  else if (editAction === 'status_edit'){
    if (!values.mobileno_disp) {
      errors.mobileno_disp = "Mobile Number is required!";
      validateErr = true;
    }
    else {
      errors.mobileno_disp = "";
    }

    if (values.mobileno_disp && values.mobileno_disp.length !== 10){
      errors.mobileno_disp = "Should be a Valid Mobile Number!";
      validateErr = true;
    }
    else {
      errors.mobileno_disp = "";
    }

    if (!values.receivedby_disp) {
      //errors.received_amt_study = getCustomerMessages(LS_MSG_TAG,"MSG_ITEMCAT_NAME_MANDATORY");
      errors.receivedby_disp = "ReceivedBy is required!";
      validateErr = true;
      //setValidateError(true);
    }
    else {
      errors.receivedby_disp = "";
    }
  }
    */

    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(true);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };

  const CallPatientListData = async () => {
    console.log("+++++++++++++ ********** CallPatientListData method called ...");
    var retStateLoad = false;
    var studyRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current,
        brid:currBranchRef.current,
        name: formValues["name"],
        mobile: formValues["mobile"] || ""
      };

      var res = await axios.post(client.domain + "/bms/getPatientSearch", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail);
        setDispatchList(res.data.detail.data.casestudy);
        setIsSearched(true);
        setPatientSelected(false);
        setToRender(true);

        studyRet = res.data.detail.data.casestudy;
        console.log("<<<<<<<<< StudyRet >>>>>>>>>>>>>>", studyRet);
        retStateLoad = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, studyRet];
  };

  const AddPatientData = async (id) => {
    console.log("+++++++++++++ ********** AddPatientData method called ...");
    var retStateLoad = false;
    var patientRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        age: formValues["age"] ,
        gender:genderIdSelect.value,
        mobile_primary: formValues["mobile_primary"],
        mobile_secondary: formValues["mobile_secondary"] || "",
        ref_no: formValues["ref_no"] || ""
      };

      var res = await axios.post(client.domain + "/bms/addPatient", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server For Patient : ", res.data.detail);
        setFormValues(res.data.detail.data);
        setToRender(true);

        patientRet = res.data.detail.data;
        console.log("<<<<<<<< PATIENT ARRAY >>>>>>>>>> :", patientRet);
        retStateLoad = true;
       
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return[retStateLoad, patientRet]
  };

  const CallHistoryListData = async (patient_id) => {
    console.log("+++++++++++++ ********** CallHistoryListData method called ...");
    var retStateLoad = false;
    var historyRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current,
        patient_id: currPatientIdRef.current
      };

      var res = await axios.post(client.domain + "/bms/getVisitHistory", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail);
        setHistoryDispatchList(res.data.detail.data.casestudy);
        //setIsSearched(true);
        setToRender(true);

        historyRet = res.data.detail.data.casestudy;
        console.log("<<<<<<<<< HistoryRet >>>>>>>>>>>>>>", historyRet);
        retStateLoad = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, historyRet];
  };


  const CallPatientData = async () => {
    console.log(" Call patient data called.....")
    var retStateLoad = false;
    var genderRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getPatientAddUpdPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.status);
        setOptGender(res.data.detail.data.genders);
        setToRender(true);

        genderRet = res.data.detail.data.genders;
        retStateLoad = true;

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, genderRet];
  };


  const CallCaseStudyData = async () => {
    console.log(" call Case study data called....")
    var retStateLoad = false;
    var typeRet = [];
    var studyRet = [];
    var itemRet = [];
    var physicianRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getCaseStudyAddUpdPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.status);
        setOptType(res.data.detail.data.item_types);
        setOptStudy(res.data.detail.data.item_categories);
        setOptStudyOrig(res.data.detail.data.item_categories);
        setOptPhysician(res.data.detail.data.ref_physicians);
        setItems(res.data.detail.data.items);
        setItemsOrig(res.data.detail.data.items);
        setDocNumberInfo(res.data.detail.data.new_study_no);
        
        setToRender(true);

        //genderRet = res.data.detail.data.genders;
        typeRet = res.data.detail.data.item_types;
        studyRet = res.data.detail.data.item_categories;
        physicianRet = res.data.detail.data.ref_physicians;
        itemRet = res.data.detail.data.items;
        console.log(" <<<<<<<<<<<<< ITEMS VALUE >>>>>>>>>>>>>>> : ", itemRet);
        retStateLoad = true;

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
         
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, typeRet, studyRet, physicianRet, itemRet];
  };

  const callPatientDetById = async(id_pass) => {
    var retState = false;
    var patientInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var reqParams = {
       id: id_pass
    }
    var res = await axios.post(client.domain + "/bms/getPatientDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") { 
        //setFormValues(res.data.detail.data[0]);
        setFormValues({ ...formValues, 
          id: res.data.detail.data[0].id,
          code: res.data.detail.data[0].code,
          bid: res.data.detail.data[0].bid,
          age: res.data.detail.data[0].age, 
          gender: res.data.detail.data[0].gender,
          mobile_primary: res.data.detail.data[0].mobile_primary,
          mobile_secondary: res.data.detail.data[0].mobile_secondary,
          name: res.data.detail.data[0].name,
          //ref_date: formatDate(new Date(res.data.detail.data[0].ref_date)), 
        });
        patientInfoRet = res.data.detail.data[0]; 
        console.log("<<<<<<<<<<< PATIENT INFO >>>>>>>>>>>>>:", patientInfoRet);
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);   
        }
      }
      setSubmitAction(false);
    }
    return [retState, patientInfoRet];   
  };
  
  useEffect(() => {
    if (!initialized.current) {
      console.log( "*************** useEffect::addUpdatePatient called ##########################");
      
      //setStudyIdSelect(optStudy[0]);
      //setDoctorIdSelect(optDoctor[0]);
      (async () => {
        var [retStateLoad, genderRet] = await CallPatientData();
        var findGender = genderRet.find(element => element.is_default === true);
              if (findGender !== undefined) {
                setGenderIdSelect(findGender);  
          }
          //if ( retStateLoad ) {
          var [retStateLoad, typeRet, studyRet, physicianRet, itemRet] = await CallCaseStudyData();
            
              var findType = typeRet.find(element => element.is_default === true);
              if (findType !== undefined) {
                setTypeIdSelect(findType);  
          
        }
        
          var findEdit = typeRet.find(element => element.itemtype_id === true);
              if (findEdit !== undefined) {
                setTypeIdSelect(findEdit);
              }
       
            var findCategory = studyRet.find(element => element.is_default === true); 
            if (findCategory !== undefined) {
              setStudyIdSelect(findCategory);  
        }

        var findPhysician = physicianRet.find(element => element.is_default === true); 
            if (findPhysician !== undefined) {
              setPhysicianIdSelect(findPhysician);  
        }
        //}
        
       setToRender(true);
          
      })(); 
      initialized.current = true;
    }
  }, []);

 
  const handlePostSaveOk = async(e) => {
    console.log("user confirmed to customer added ...");
    setShowAlert(false);
    if( alertPurpose === "save"){
      setIsEditable(false);
      setStatusState(false);
      console.log("SaveOk action called for dialog confirm **************************");
    }

    else if (alertPurpose === 'warning'){ 
      setPatientSelected(false);
      setIsSearched(true);
    }
    else if (alertPurpose === 'submit_success') {
      setAlertPurpose("init");
      navigate(0);
    }
    else if (alertPurpose === 'submit_failed') {
      console.log("SUBMIT FAILED PLEASE TRY AGAIN!");
      setAlertPurpose("init");
    }
    else{
      console.log("Action called for dialog confirm **************************");
      setAlertPurpose("init");
    }
  };

  const handleCloseDialog = () => {
    setShowAlert(false);
    if (alertPurpose === 'confirmation-status'){ 
      setStatusState(false);
      setIsEditable(true);
     }
      else if (alertPurpose === 'confirmation-amount'){ 
        setIsEditable(false);
        setStatusState(true);
     }
  };

  const handleCancelDialog = () => {
   console.log("Handle Cancel Dialog called...");
   setShowAlertDialog(false);
  };

  const handleStudyCloseDialog = () => {
    console.log("Handle Study Close Dialog...");
    setShowAlertDialog(false);
  }

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    console.log("FORM VALUES at handleSubmit: ", formValues);
    validateErr = false;
    setFormErrors(validate(formValues));
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
    if (validateErr) {
      return -1;
    }

    var selectedCategory = [];
    items.map((item) =>{
      if ( item.checked_state ) {
        selectedCategory.push(item);
      }
    })
    console.log("SELECTED CATEGORY : ", selectedCategory);
    console.log("CASE STUDY DATE BEFORE SUBMIT : ", formValues.study_date_field);

    
    var doc_sno = docNumberInfo.new_study_no.substring(docNumberInfo.new_study_no.lastIndexOf("/") + 1);
    var doc_prefix = docNumberInfo.new_study_no.substring(0, docNumberInfo.new_study_no.lastIndexOf("/"));
    console.log("Doc Numbering STUDY PREFIX : ", doc_prefix);
    console.log("Doc Numbering SERIAL NUMBER : ", doc_sno);
    

    var reqAddUpdateStudy = {
      id: 0,
      bid: currSelBusinessRef.current,
      brid:currBranchRef.current,
      study_type: typeIdSelect.value,
      //study_no: study_no_ref.current,
      //study_sno: study_sno_ref.current,
      study_no: docNumberInfo.new_study_no,
      study_sno: doc_sno,
      study_date: formValues.study_date_field || new Date(),
      patient_id : formValues.id,
      physician_id: physicianIdSelect.value,
      total_amt: formValues.total_amt,
      discount_amt: formValues.discount_amt,
      net_amt: formValues.bill_amt,

      study_cat_items: selectedCategory 
    };

    //const url = client.domain + "/bms/uploadFiles";
    const formData = new FormData();

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
  };
    selectedDocs.map((doc) =>{
      formData.append('files', doc);
      formData.append('fileName', doc.name);
    })

    formData.append('addl_info', JSON.stringify(reqAddUpdateStudy));

    /*
8    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
    });
    */

    try {
      setSubmitAction(true);
      var res = await axios.post(client.domain + "/bms/insertCaseStudy", formData, config, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setAlertTitle("SUCCESS!");
        setAlertBody("CASE STUDY INSERTED SUCCESSFULLY ...");
        setAlertPurpose("submit_success");
        setShowAlert(true);
        setSubmitAction(false); 
      }
      else {
        setAlertTitle("FAILED!");
        setAlertBody("FILES UPLOAD FAILED PLEASE TRY AGAIN ...");
        setAlertPurpose("submit_failed");
        setShowAlert(true);
        setSubmitAction(false);
      }
    } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log("Not able to reach target server please try after sometime");
    } else {
      console.log("ERROR : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log("Request failed due to ", error.response.data.detail.data);
      
        setAlertTitle("FAILED!");
        setAlertBody(error.response.data.detail.data);
        setAlertPurpose("submit_failed");
        setShowAlert(true);
        setSubmitAction(false);
      }
    }
    setSubmitAction(false);
  }

   /*
    var apiToCall = "";
    //if (state.action === "add") {
    if ( editAction === 'status_edit' ) {
      apiToCall = "/bms/updDispatch";
    }
    else if (editAction === 'amount_edit'){
      apiToCall = "/bms/addupdReceipt";
    }
    //} 
    //else {
      //apiToCall = "/bms/updDispatch";
    //}

    var reqParam = {};

   if(editAction === 'status_edit' ) {
      reqParam = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current,
        cs_id: dispatchList[selectedRadio].cs_id,
        disp_date:formValues["disp_date"],
        receivedby:formValues["receivedby_disp"],
        mobileno: formValues["mobileno_disp"],
        notes: formValues["notes_disp"]
      };
    }
    else if ( editAction === 'amount_edit'){
      reqParam = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current,
        //cs_id:formValues["cs_id"],
        cs_id: dispatchList[selectedRadio].cs_id, 
        receipt_date: formValues["receipt_date_study"],
        bill_amount: formValues["bill_amt_study"],
        received_amount: formValues["received_amt_study"],
        balance_amount: formValues["balance_amt_study"],
        notes: formValues["notes_study"]
      };
    }
   

     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-add') {
         setSubmitAddAction(true);
         submitActionRef.current = "save-add";
       }
       else {
         setSubmitAction(true);
         submitActionRef.current = "save";
       }
       var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
       //var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.data);
         console.log("ACTION VALUE PASSED++++++++++++++++++++++++++-------------: ", actionVal);
         if ( actionVal === 'save-add' ) {
           setSubmitAddAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertPurpose("save")
         setAlertTitle("Success");
         //setShowAlert(true);
         setSubmitAction(false);
         
         if(editAction === 'status_edit' ) {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_DISPATCH_UPDATED"));
         }
         else {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_RECEIPT_UPDATED"));
         }
        
           setShowAlert(true);
          
       }
       else {
        setAlertPurpose("failed")
        setAlertTitle("FAILED!");
         setShowAlert(true);
         setSubmitAction(false);
         
         if(editAction === 'status_edit' ) {
           setAlertBody("Dispatch Update Failed!");
         }

         else{
          setAlertBody("Receipt Update Failed!")
         }
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       setSubmitAction(false);
     }
       */
     
  };
  
  /*
    const handleCheckBox = async (e) => {
      console.log("handle copy to billing called :", e);
      console.log("handle copy to billing called CHECKED :", e.target.checked); 
      setCheckState(e.target.checked);   
    };
    */


    const handleSearch = async (e) => {
      e.preventDefault();
      console.log("handle search called : ", e);
      validateErr = false;
      setFormErrors(validateSearch(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        console.log(">>>>>>>>>>>>>>>>> VALIDATE FAILED FOR SEARCH SUBMIT <<<<<<<<<<<<");
        return 0;
      }
      setToRender(false);
      await CallPatientListData();

    };
  
    const handleClearSearch = (e) => {
      console.log("handle  clear search called : ", e);
      navigate(0)
      //setPatientList(patientsListOrig);
      //setFormValues(intialValues);
      //setPatientList(patientListFull);
    };

    /*
    const handleAmountEdit = (e) => {
      console.log("handle  Amount search called : ", e);
      console.log("STUDY RECEIPT IN DISPATCH :", dispatchList[selectedRadio].study_receipt[0]);
      
      //selectedRadio(true)
      setFormValues({ ...formValues, bill_amt_study: dispatchList[selectedRadio].study_receipt[0].bill_amt, received_amt_study: dispatchList[selectedRadio].study_receipt[0].received_amt, balance_amt_study: dispatchList[selectedRadio].study_receipt[0].balance_amt, notes_study: dispatchList[selectedRadio].study_receipt[0].notes, receipt_no_study: dispatchList[selectedRadio].study_receipt[0].receipt_no, receipt_date_study: dispatchList[selectedRadio].study_receipt[0].receipt_date});
      
      if ( !statusState ) {
        setIsEditable(true);
      }
      if (statusState ) {
        setShowAlert(true);
        setAlertPurpose("confirmation-amount");
        setAlertTitle("Confirmation");
        setAlertBody("Dispatch Details Will be Cleared.Continue to proceed");
        //setStatusState(false);
      }
      setEditAction("amount_edit");
    };
    */
   
  
    const handleModalEdit = (e) => {
      console.log("handle  Status search called : ", e);
      console.log("TARGET VALUE :", e.target);
      console.log("CURRENT TARGET VALUE :", e.currentTarget);
      console.log("CURRENT TARGET ID VALUE :", e.currentTarget.id);
      console.log("CURRENT TARGET NAME :", e.currentTarget.name);
      if ( selectedRadio >= 0 ) {
        var currentRowName = e.currentTarget.id.substring(e.currentTarget.id.lastIndexOf("-") + 1);
        var nameOfElement = e.currentTarget.id.substring(0, e.currentTarget.id.lastIndexOf("-"));
        console.log("Name of element at handleDialogRadioChange : ", nameOfElement);
        console.log("Current row id at handleDialogRadioChange : ", currentRowName);
        //setSelectedRadio(parseInt(currentRowName));
        setSelectedPatientRow(parseInt(currentRowName));

     

        //var idTag = e.currentTarget.id;

        setShowAlertDialog(true);
        setAlertTitleDialog("testing");
        setAlertBodyDialog("DIALOG");
      }
      else {
        setAlertTitle("WARNING!");
        setAlertBody("Please select the patient by clicking radio button ...");
        setAlertPurpose("warning");
        setShowAlert(true);
        setSubmitAction(false);
      }
    };
  
 
    const handleValueChange = (e) => {
      //e.preventDefault();
      console.log("Clicked value : ", e);
      const { name, value } = e.target;
      console.log("Captured input element name : ", name);
      console.log("Captured input element value : ", value);
      
      if (name === "total_amt" || name === "discount_amt" || name === "bill_amt")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        var bill_amt_temp = 0.00;
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        if ( name === 'total_amt') {
          //var igst_per_tmp = 0.00;
          console.log("TOTAL AMOUNT  check : ", parseFloat(value));
          console.log("BILL AMOUNT check : ", parseFloat(formValues["discount_amt"]));
          if ( !isNaN(parseFloat(value)) && !isNaN(parseFloat(formValues["discount_amt"])) ) {
            //setFormValues({ ...formValues, igst_per: (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2)});
            bill_amt_temp = (parseFloat(value) - parseFloat(formValues["discount_amt"])).toFixed(2);
          }
          else if( isNaN(parseFloat(value)) && !isNaN(parseFloat(formValues["discount_amt"])) ) {
            //setFormValues({ ...formValues, igst_per: (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2)});
            bill_amt_temp = parseFloat(formValues["discount_amt"]);
          }
          else if( !isNaN(parseFloat(value)) && isNaN(parseFloat(formValues["discount_amt"])) ) {
            //setFormValues({ ...formValues, igst_per: (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2)});
            bill_amt_temp = parseFloat(value);
          }
          else {
            console.log("ELSE CALLED at cgst check ?????????????????????????");
            //setFormValues({ ...formValues, igst_per: 0.00});
            bill_amt_temp = 0.00;
          }
        }
        else if ( name === 'discount_amt' ) {
          console.log("TOTAL AMOUNT check : ", parseFloat(formValues["total_amt"]));
          console.log("DISCOUNT AMOUNT check : ", parseFloat(value));
          if ( !isNaN(parseFloat(formValues["total_amt"])) && !isNaN(parseFloat(value)) ) {
            console.log("<<<<<<<<<<<<<<<<< BILL AMOUNT UPDATED >>>>>>>>>>>>>>>>>", (parseFloat(formValues["total_amt"]) - parseFloat(value)).toFixed(2));
            //setFormValues({ ...formValues, igst_per: (parseFloat(formValues["cgst_per"]) + parseFloat(value)).toFixed(2)});
            bill_amt_temp = (parseFloat(formValues["total_amt"]) - parseFloat(value)).toFixed(2);
          }
          else if ( !isNaN(parseFloat(formValues["total_amt"])) && isNaN(parseFloat(value)) ) {
            bill_amt_temp = parseFloat(formValues["total_amt"]);
          }
          else if ( isNaN(parseFloat(formValues["total_amt"])) && !isNaN(parseFloat(value)) ) {
            bill_amt_temp = parseFloat(value);
          }
          else {
            console.log("ELSE CALLED at sgst check ?????????????????????????");
            //setFormValues({ ...formValues, igst_per: 0.00});
            bill_amt_temp = 0.00;
          }
        } 
        setFormValues({ ...formValues, [name]: e.currentTarget.value, bill_amt: bill_amt_temp }); 
      }
    }
      else{ 
      setFormValues({ ...formValues, [name]: value });
      }
      
    };

    const dispatchRowClick = async (e, id) => {
      console.log("Dispatch row clicked : ", e);
      console.log("Dispatch row clicked Target : ", e.target);
      console.log("Dispatch row clicked CURRENT Target: ", e.currentTarget);
      console.log("Dispatch row clicked CURRENT Target CHECKED : ", e.target.checked);
      setCheckState(e.target.checked)
      var selectedTagId = e.target.id;
      if (selectedTagId) {
        var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
        var nameOfElement = selectedTagId.substring( 0, selectedTagId.lastIndexOf("-"));
        console.log("Name of element VIEW: ", nameOfElement);
        console.log("Current id VIEW : ", currentId);
        //console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ", patientList[currentId]);
        setCurSelRowRadio(parseInt(currentId));
      }
      console.log("Clicked at : ", id.idx);
    };

    const handleDialogRadio = async (e) => {
      console.log("handleDialogRadio called  : ", e);
      const { name, id } = e.currentTarget;
      console.log("Captured value name at handleDialogRadioChange : ", name);
      console.log("Captured value id at handleDialogRadioChange : ", id);
      var currentRowName = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element at handleDialogRadioChange : ", nameOfElement);
      console.log("Current row id at handleDialogRadioChange : ", currentRowName);
      setSelectedRadio(parseInt(currentRowName));
      //var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcId(dcList[currentRowName].indc_id, selectedRowId);
      var selectedRowData = dispatchList[currentRowName];
      var patientCode = selectedRowData.patient_code;
      var patientIdPass = selectedRowData.patient_id;
      console.log("<<<<<<<<<< Patient ID >>>>>>>>>>>: ", selectedRowData.patient_id);
      currPatientIdRef.current = selectedRowData.patient_id;
      var [retStateLoad, typeRet] = await CallPatientListData();
      var [retStateLoad, historyRet] = await CallHistoryListData(selectedRowData.patient_id);

           //var dispatchListNew = dispatchList.slice();
            //dispatchListNew[selectedRadio].study_receipt[0] = selectDispatchReceipt;
            //setDispatchList(dispatchListNew);
      //await callPatientDetById(currPatientIdRef.current);

      //selectedRowData
    };

    const onChangeDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called for Study Date ...", e.target.value);
      setFormValues({ ...formValues, study_date_field: e.target.value });
    };

    const handleRadioChange = async (e) => {
      console.log("On change clicked : ", e);
      var tagId = e.target.id;
      var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
      var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current row id : ", currentRowName);
      setRadioSelect(currentRowName);
  
      if (currentRowName === "searchpatient") {
        setShowSearchItems(true);
        setShowAddItems(false);
        setPatientSelected(false);
        setFormValues({...formValues,["patient"]: currentRowName,});
      } 
      else if (currentRowName === "newpatient") {
        setShowAddItems(true);
        setShowSearchItems(false);
        setIsSearched(false);
        setPatientSelected(false);
        setFormValues({...formValues,["patient"]: currentRowName,});
      } 
    };

    const handleGenderRadioChange = async (e) => {
      console.log("Value button clicked : ", e);
      const { name, id } = e.currentTarget;
      console.log("Captured value name at handleRadioChange : ", name);
      console.log("Captured value id at handleRadioChange : ", id);
      var currentRowName = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element at handleRadioChange : ", nameOfElement);
      console.log("Current row id at handleRadioChange : ", currentRowName);
      //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
      setGenderIdSelect({ value: currentRowName, label: e.currentTarget.label })
    };

    const handleTypeRadioChange = async (e) => {
      console.log("Value button clicked : ", e);
      const { name, id } = e.currentTarget;
      console.log("Captured value name at handleRadioChange : ", name);
      console.log("Captured value id at handleRadioChange : ", id);
      
      var currentRowName = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element at handleRadioChange : ", nameOfElement);
      console.log("Current row id at handleRadioChange : ", currentRowName);
      var studyNew = [];
      optStudyOrig.map((study) => {
        if (study.itemtype_id === parseInt(currentRowName)) {
          studyNew.push(study);
        }
      })
      setOptStudy(studyNew);
      setTypeIdSelect({ value: parseInt(currentRowName), label: e.currentTarget.label });

      var itemsNew = [];
      itemsOrig.map((item) => {
        if ( (item.itemcategory_id === studyIdSelect.value || studyIdSelect.value === 0) && item.itemtype_id === parseInt(currentRowName)) {
          //if( (item.itemcategory_id === studyIdSelect.value && item.itemtype_id === parseInt(currentRowName)) ){
          itemsNew.push(item);
        }
      })
      setItems(itemsNew);
    };

    const handleOnChange = (e) => {
      //e.preventDefault();
      console.log("Button clicked : ", e);
  
      console.log("Clicked value : ", e.currentTarget);
      const { name, value } = e.currentTarget;
      console.log("Captured input element name : ", name);
      console.log("Captured input element value : ", value);
  
      if (name === "age" || name === "mobile_primary" || name === "mobile_secondary")  {
        console.log("Inside check ******************");
        //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
        if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
          //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
          setFormValues({ ...formValues, [name]: e.target.value });
        }
      } 
     
      else {
        setFormValues({ ...formValues, [name]: value });
      }  
    };

    const handleClinicalNote = async (e) => {
      console.log("handle clinical note clicked : ", e);
      if (radioSelect === "searchpatient") {
      if (curSelRowRadio < 0){
        setShowAlert(true);
        setAlertPurpose("warning");
        setAlertTitle("Warning");
        setAlertBody("Please select Patient...")
      }
      else {
        setPatientSelected(true);
        setIsSearched(false);
        await callPatientDetById(currPatientIdRef.current)
      }
    }

    else  {
    
       setFormErrors(validate(formValues));
        console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
        if (validateErr) {
          return -1;
        }
        
      var [retStateLoad, patientRet] = await AddPatientData();
      console.log("RETURNED PAIENT ID : ", patientRet.id);
      setPatientSelected(true);
      await callPatientDetById(patientRet.id);
      
    } 
    
    };

    
    const handleStudySelect = (e, data) => {
      console.log("handleStudySelect called ...", e);
      setStudyIdSelect(e);
      var itemsNew = [];
      itemsOrig.map((item) => {
        if ( item.itemcategory_id === e.value && item.itemtype_id === typeIdSelect.value) {
        //if (item.itemtype_id === typeIdSelect.value){
          itemsNew.push(item);
        }
      })
      setItems(itemsNew);
    
    
    };

    const handlePhysicianSelect = (e, data) => {
      console.log("handlePhysicianSelect called ...", e);
      setPhysicianIdSelect(e);
    };

    const handleCheckBox = async (e) => {
      console.log("handle copy to billing called :", e);
      console.log("handle copy to billing called CHECKED :", e.target.checked); 
      setCheckState(e.target.checked);   
    };

    const handleCheckBoxOnChange = async(e) => {
      console.log("Button clicked handleCheckBoxOnChange : ", e);
  
      console.log("Clicked value handleCheckBoxOnChange : ", e.target);
      const { name, id, checked, label } = e.target;
      console.log("Captured input element name handleCheckBoxOnChange : ", name);

      var selectedNameTag = e.target.name;
      var currentId = selectedNameTag.substring(selectedNameTag.lastIndexOf("-") + 1);
      var nameOfElement = selectedNameTag.substring( 0, selectedNameTag.lastIndexOf("-"));
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log("Captured input element id handleCheckBoxOnChange : ", id);
      console.log("Captured input element check state handleCheckBoxOnChange : ", checked);
      console.log("Captured input element check state handleCheckBoxOnChange Target : ", e.target);
      console.log("Captured input element check label handleCheckBoxOnChange : ", e.target.labels[0].innerText);
      console.log("Captured input element check label charges : ", e.target.getAttribute("charges"));
      setCheckBoxState(checked);
      
      //var findItem = items.find((element)=> element.value === id)
      //findItem.checked_state = true;
      var selectedStudy = items[currentId];
      selectedStudy.checked_state = e.target.checked;
      selectedStudy.charges = parseFloat(e.target.getAttribute("charges"));
      var itemsNew = items.slice();
      itemsNew[currentId] = selectedStudy;

      
      var total_sum = 0.00;
      itemsNew.map((itemInd, idx) => {
        console.log(" >>>>>>>>>>>>>>>>> INDIVIDUAL ITEM <<<<<<<<<<<<<<<<<<<<<<<< : ", itemInd);
        if ( itemInd.checked_state ) {
        total_sum = total_sum + itemInd.charges;
        }
      })
      var net_amt_sum = 0.00;
      net_amt_sum = total_sum - formValues.discount_amt;

      setFormValues({ ...formValues, total_amt: total_sum, bill_amt: net_amt_sum});
      
      setItems(itemsNew);
      //var checkedItems = items.slice();
      //setCheckedItems();
  
  
      //setProductSelectedList({ ...productSelectedList, [id]: checked});
      /*
      if ( checked === true ) {
        //setRefresh(false);
        //formValues["products"][id.toString()] = {id: parseInt(id), name: e.currentTarget.labels[0].innerText, uom: "kg"};
        console.log("<<<<<<<<<<<<< products in formValues >>>>>>>>>>>>>>>", formValues["products"]);
        formValues["products"].push({id: parseInt(id), name: e.currentTarget.labels[0].innerText, uom: "kg"})
        //setRefresh(true);
        //setFormValues( ...formValues);
        //setFormValues({ ...formValues["products"], [id]: {id: id, name: e.currentTarget.labels[0].innerText, uom: "kg"}})
        var newFormValues = Object.create(formValues);
        setFormValues(newFormValues);
      }
      else {
        console.log("FORMVALUES products : ", formValues["products"]);
        const index = formValues["products"].findIndex(key => key.id === parseInt(id));
        console.log("Searched Index : ", index);
        if (index >= 0 ) {
          formValues["products"].splice(index,1);
  
          var newFormValues = Object.create(formValues);
          setFormValues(newFormValues);
        }
      }
      
      setFormValues({ ...formValues, formValues["products"]: });
      */
     
    };

    const handleViewFile = async (e) => {
      console.log("Handle pdf File clicked : ", e);
      e.preventDefault();
      console.log("CALLED CURRENT PAGE PRINT DOCUMENT : ", window.location.href);
      console.log("CALLED window locaion PRINT DOCUMENT : ", window.location);
      console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);
      console.log(">>>>>>>>>>>>>>>> EVENT FOR target <<<<<<<< : ", e.target);
      console.log(">>>>>>>>>>>>>>>> EVENT FOR target ID <<<<<<<< : ", e.target.id);
      console.log(">>>>>>>>>>>>>>>> EVENT FOR target NAME <<<<<<<< : ", e.target.name);
      console.log("<<<<<<<<<<<< CLICKED FILE LOCATION AT handleViewFile >>>>>>>>>>>> : ", e.target.getAttribute("vb_file"));
      var file_to_view = e.target.getAttribute("vb_file");
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);

      //https://api.dcl.avinasoft.in/uploadedfiles/test_pdf1.pdf
      //var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/uploadedfiles/test_pdf1.pdf`, "width=" + window.screen.width + ",height=" + window.screen.height);
      var winPrint = window.open(`${client.domain}/${file_to_view}`, "width=" + window.screen.width + ",height=" + window.screen.height);
      //var idPass = state.passed_element.id;    
     
      //var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printInvoice?id=${idPass}`, "width=" + window.screen.width + ",height=" + window.screen.height);
      //winPrint.moveTo(0,0);
      //winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    };

    const handleDownloadFile = async(e) => {
      console.log("CALLED CURRENT PAGE PRINT DOCUMENT at handleDownloadFile: ", window.location.href);
      console.log("CALLED window locaion PRINT DOCUMENT at handleDownloadFile : ", window.location);
      console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT at handleDownloadFile : ", __dirname);
      console.log("<<<<<<<<<<<< CLICKED FILE LOCATION AT handleDownloadFile >>>>>>>>>>>> : ", e.target.getAttribute("vb_file"));
      var file_to_download = e.target.getAttribute("vb_file");

      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL  at handleDownloadFile : ", urlTmp);
      console.log("CALLED PAGE at handleDownloadFile : ", paramsTmp);
      //const urlTmp = "https://api.dcl.avinasoft.in/uploadedfiles/test_pdf1.pdf";
      //const pdfUrl = "https://api.dcl.avinasoft.in/";
      var url_path = client.domain + "/" + file_to_download;
      /*
      var file = new Blob(
        [
          url_path
        ],
        { type: "image/*" }
      );
      */
      const link = document.createElement("a");
      link.href = url_path;
    
      //link.href = pdfUrl;
      //link.href = urlTmp
      //link.download = client.domain + "/" + file_to_download; // specify the filename
      link.download = file_to_download;
      document.body.appendChild(link);
      link.click();
      //document.body.removeChild(link);
      link.parentNode.removeChild(link);
      
      //saveAs('image_url', url_path);
  };
  

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

  const handleFileChange = (e) => {
    console.log("handleFileChange clicked : ", e);
    console.log("selected files : ", e.target.files);
    console.log("selected files 1st: ", e.target.files[0].name);
    //setFile1(URL.createObjectURL(e.target.files[0]));
    setFile1(e.target.files[0].name);
    console.log("File1 content: ", file1);
    //window.open(URL.createObjectURL(e.target.files[0]));
    //setSelectedDocs(Array.from(e.target.files));
    selectedDocsRef.current.push(e.target.files[0]);
    var selectedDocsTmp = selectedDocs;
    selectedDocsTmp.push(e.target.files[0]);
    setSelectedDocs(selectedDocsTmp);
  };

  const handleDocItemDelete = async(e) => {
    setToRender(false);
    console.log("HandleDocItemDelete called ...", e);
    console.log("HandleDocItemDelete called TARGET : ", e.target);
    
    console.log("HandleDocItemDelete called CURRENT TARGET : ", e.currentTarget);
    console.log("HandleDocItemDelete called CURRENT TARGET NAME : ", e.currentTarget.attributes.name);
    console.log("HandleDocItemDelete called CURRENT TARGET VALUE : ", e.currentTarget.value);

    var selectedTagName = e.currentTarget.attributes.name.value;
    var currentId = selectedTagName.substring(selectedTagName.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagName.substring( 0, selectedTagName.lastIndexOf("-"));
    console.log("Name of element VIEW: ", nameOfElement);
    console.log("Current id VIEW : ", currentId);

    /*
    var selectedDocsNew = selectedDocsRef.current;
    selectedDocsNew.splice(currentId, 1);
    selectedDocsRef.current = selectedDocsNew;

    selectedDocsRef.current.map(doc => {
      console.log("DOCUMENT INDIVIDUAL : ", doc);
    })
    setToRender(true);
    */
    var selectedDocsNew = selectedDocs.slice();
    selectedDocsNew.splice(currentId, 1);
    selectedDocsNew.map(doc => {
      console.log("DOCUMENT INDIVIDUAL : ", doc);
    })
    setSelectedDocs(selectedDocsNew);
    setToRender(true);
  }
  

  const handleUploadFile = async(e) => {
    console.log("HandleUploadFile called : ", e);

    //const url = 'http://localhost:9201/bms/uploadFiles';
    const url = client.domain + "/bms/uploadFiles";
    const formData = new FormData();

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
  };
    selectedDocs.map((doc) =>{
      formData.append('files', doc);
      formData.append('fileName', doc.name);
      formData.append('addl_info', '1234567891');
    })
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
    });
  }
 
console.log("<<<<<<<<<<<< Dispatch List >>>>>>>>>> : ", dispatchList);
  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'addUpdateStudy' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'addUpdateStudy')).page_title_addupd : "Case Study"}</span>
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>

               {/* 
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                //disabled={menuSettings.permissions.find(element => (element.code === 'listItemCategories' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                */}
                
              </div>
            </Col>
          </Row>
          <hr align="center" />
          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Label>Case Study for:</Form.Label>
            </Col>
            <Col xs={7} md={7} sm={7} lg={7}>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  disabled={isReadOnly}
                  inline
                  label="New Patient"
                  name="patient"
                  type={"radio"}
                  checked={showAddItems}
                  id={`inline-radio-newpatient`}
                  onChange={(e) => handleRadioChange(e)}
                />
                <Form.Check
                  disabled={isReadOnly}
                  inline
                  label="Existing Patient"
                  name="patient"
                  type={"radio"}
                  checked={showSearchItems}
                  id={`inline-radio-searchpatient`}
                  onChange={(e) => handleRadioChange(e)}
                />
              </div>
            </Col>
          </Row>
          {showSearchItems && (
          <Row>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Patient Mobile"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.mobile}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.mobile}</p>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
              type="text"
              name="name"
              id="name"
              placeholder="Patient Name"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.name}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.name}</p>
          </Col>

          <Col xs={1} md={1} sm={1} lg={1}>
            <Button
              variant="warning"
              id="btn-search-id"
              name="btn-search-name"
              //type="submit"
              onClick={handleSearch}>
              Search
            </Button>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
            <Button
              variant="secondary"
              id="btn-search-id"
              name="btn-search-name"
              onClick={handleClearSearch}
            >
              Clear Search
            </Button>
          </Col>
          </Row>
          )}

        {showAddItems && !patientSelected && (
          <div className="ui-form"> 
          <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues.name}
                    style={{border: !isReadOnly && "1px solid gray"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
              
              <Col xs={1} md={1} sm={1} lg={1}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Age</Form.Label>
                  <Form.Control
                  type="text"
                    id="age"
                    name="age"
                    placeholder="Age"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={3}
                    value={formValues.age}
                    style={{border: !isReadOnly && "1px solid gray"}}
                  />
                  </Form.Group>
                <p className="error-msg">{formErrors.age}</p>
              </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Label>Gender</Form.Label>
                <Form.Group>

                  {optGender.map((genderInd, idx) => (
                    <>
                      <Form.Check
                        disabled={isReadOnly}
                        inline
                        label={genderInd.label}
                        name="gender"
                        type={"radio"}
                        id={`radio-gender-${genderInd.value}`}
                        onChange={(e) => handleGenderRadioChange(e)}
                        //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                        checked={genderIdSelect.value === genderInd.value ? true : false}
                      />
                    </>
                  ))}
                </Form.Group>
              </Col>
              
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_primary"
                    placeholder="Mobile"
                    value={formValues.mobile_primary}
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength="10"
                    style={{border: !isReadOnly && "1px solid gray"}}
                  />
               </Form.Group>
                <p className="error-msg">{formErrors.mobile_primary}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Alternative Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_secondary"
                    placeholder="Alternative Mobile"
                    value={formValues.mobile_secondary}
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength="10"
                    style={{border: !isReadOnly && "1px solid gray"}}
                  />
               </Form.Group>
                <p className="error-msg">{formErrors.mobile_secondary}</p>
              </Col>
              </Row>
              <Row className="d-flex justify-content-center">
              <Col
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className="float-end justify-content-center"
              >
                <Button onClick={handleClinicalNote}>Enter Case Studies</Button>
              </Col>
            </Row>
            </div>
        )}
          { isSearched && (
              <>
          <div className="ui-form">
            
            <Row>
              <h4>Patients</h4>
            </Row>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{width: "3%"}}>...</th>
                  <th className="table-row-heading" style={{width: "3%"}}>S.No</th>
                  <th className="table-row-heading" style={{width: "8%"}}>Study Type</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Study ID</th>
                  <th className="table-row-heading" style={{width: "9%"}}>Study Date</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Ref.Physician</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Patient ID</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Patient Name</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Patient Mobile</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Bill Amount</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Received Amount</th>
                  <th className="table-row-heading" style={{width: "8%"}}>Balance Amount</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Report Status</th>
                  <th className="table-row-heading" style={{width: "3%"}}>...</th>
                </tr>
              </thead>
              <tbody>
                {dispatchList.map((dispatch, idx) => (
                  <tr
                    key={`dispatch-tr-key-${idx}`}
                    onClick={(e, id = { idx }) => dispatchRowClick(e, id)}
                    id={`dispatch-tr-id-${idx}`}
                    name={`dispatch-tr-name-${idx}`}
                    //charges={`dispatch-tr-charges-${idx}`}
                    //payment={`dispatch-tr-payment-${idx}`}
                  >
                    <td style={{width: "3%"}}>
                    <Form.Check
                                  //disabled={isReadOnly}
                                  inline
                                  name={`dc-radio-${idx}`}
                                  type={"radio"}
                                  id={`dc-radio-${idx}`}
                                  onChange={(e) => handleDialogRadio(e)}
                                  //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                                  checked={idx === selectedRadio ? true : false}
                    ></Form.Check>
                    </td>
                    <td key={idx} style={{width: "3%"}}>{idx + 1}</td>
                    <td style={{width: "8%"}}>{dispatch.study_type}</td>
                    <td style={{width: "7%"}}>{dispatch.study_no}</td>
                    <td style={{width: "9%"}}>{dispatch.study_date}</td>
                    <td style={{width: "10%"}}>{dispatch.physician}</td>
                    <td style={{width: "7%"}}>{dispatch.patient_code}</td>
                    <td style={{width: "10%"}}>{dispatch.patient_name}</td>
                    <td style={{width: "7%"}}>{dispatch.patient_mobile}</td>
                    {/*<td style={{width: "7%", textAlign: "right"}}>{(dispatch.hasOwnProperty('study_receipt') && dispatch.study_receipt !== null) ? dispatch.study_receipt[0].bill_amt : "0.00"}</td>
                    <td style={{width: "10%", flexDirection: "row", textAlign: "right"}}>
                      
                      {(dispatch.hasOwnProperty('study_receipt') && dispatch.study_receipt !== null) ? dispatch.study_receipt[0].received_amt : "0.00"}
                      
                       
                      </td>
                    <td style={{width: "8%", textAlign: "right"}}>{(dispatch.hasOwnProperty('study_receipt') && dispatch.study_receipt !== null) ? dispatch.study_receipt[0].balance_amt : "0.00"}</td>*/}
                    
                    <td style={{width: "7%", textAlign: "right"}}>{dispatch.bill_amt}</td>
                    <td style={{width: "10%", flexDirection: "row", textAlign: "right"}}>{dispatch.received_amt}</td>
                    <td style={{width: "8%", textAlign: "right"}}>{dispatch.balance_amt}</td>


                    <td style={{width: "10%", flexDirection: "row", textAlign: "left"}}>
                      {dispatch.disp_status}
                      {/*
                      {curSelRowRadio === idx &&
                      <>
                       &nbsp;&nbsp;
                      <BsPencilSquare
                        size={18}
                        style={{ color: "blue", cursor: "pointer"}}
                        onClick={(e) =>  handleStatusEdit(e, 'notes')}
                        name={`composite-edit-icon-${idx}`}
                        id={`composite-edit-icon-${idx}`}
                        />
                       </>
                      }
                       */}
                    </td>
                    <td style={{width: "3%", flexDirection: "row", textAlign: "left"}}>
                      <BsCardText
                        size={18}
                        style={{ color: "blue", cursor: "pointer"}}
                        onClick={(e) =>  handleModalEdit(e)}
                        name={`modal-edit-icon-${idx}`}
                        id={`modal-edit-icon-${idx}`}
                        />
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Row className="d-flex justify-content-center">
              <Col
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className="float-end justify-content-center"
              >
                <Button onClick={handleClinicalNote}>Enter Case Study</Button>
              </Col>
            </Row>
            </>
          )}

          
          {patientSelected && (
          <div>
              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Patient Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <hr align="center" />
              <div
                style={{
                  backgroundColor: "#f6fcfe",
                  border: "2px",
                  borderRadius: "8px",
                  paddingLeft: "10px",
                }}
              >
          <div className="ui-form">
                  <Row>
                  <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Patient Id:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {formValues.code}
                      </Form.Label>
                      </Col>

                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Name:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {formValues.name}
                      </Form.Label>
                      </Col>

                      <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Age:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {formValues.age}
                      </Form.Label>
                      </Col>

                      <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Gender:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {formValues.gender}
                      </Form.Label>
                      </Col>

                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Mobile:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {formValues.mobile_primary}
                      </Form.Label>
                      </Col>

                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Alternative Mobile:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {formValues.mobile_secondary}
                      </Form.Label>
                      </Col>
                      </Row>
                      
                </div>
                </div>
                <br></br>
                <Row>
                  
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Visit History</Accordion.Header>
                    <Accordion.Body>
                    <table className="table table-bordered table-striped">
              <thead>
                <tr className="table-primary">
                  {/*<th className="table-row-heading" style={{width: "3%"}}>...</th>*/}
                  <th className="table-row-heading" style={{width: "3%"}}>S.No</th>
                  <th className="table-row-heading" style={{width: "8%"}}>Study Type</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Study ID</th>
                  <th className="table-row-heading" style={{width: "9%"}}>Study Date</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Ref.Physician</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Patient ID</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Patient Name</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Patient Mobile</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Bill Amount</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Received Amount</th>
                  <th className="table-row-heading" style={{width: "8%"}}>Balance Amount</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Report Status</th>
                  <th className="table-row-heading" style={{width: "3%"}}>...</th>
                </tr>
              </thead>
              <tbody>
                {historyDispatchList.map((dispatch, idx) => (
                  <tr
                    key={`dispatch-tr-key-${idx}`}
                    onClick={(e, id = { idx }) => dispatchRowClick(e, id)}
                    id={`dispatch-tr-id-${idx}`}
                    name={`dispatch-tr-name-${idx}`}
                    //charges={`dispatch-tr-charges-${idx}`}
                    //payment={`dispatch-tr-payment-${idx}`}
                  >
                    {/*
                    <td style={{width: "3%"}}>
                    <Form.Check
                                  //disabled={isReadOnly}
                                  inline
                                  name={`dc-radio-${idx}`}
                                  type={"radio"}
                                  id={`dc-radio-${idx}`}
                                  onChange={(e) => handleDialogRadio(e)}
                                  //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                                  checked={idx === selectedRadio ? true : false}
                    ></Form.Check>
                    </td>
                    */}
                    <td key={idx} style={{width: "3%"}}>{idx + 1}</td>
                    <td style={{width: "8%"}}>{dispatch.study_type}</td>
                    <td style={{width: "7%"}}>{dispatch.study_no}</td>
                    <td style={{width: "9%"}}>{dispatch.study_date}</td>
                    <td style={{width: "10%"}}>{dispatch.physician}</td>
                    <td style={{width: "7%"}}>{dispatch.patient_code}</td>
                    <td style={{width: "10%"}}>{dispatch.patient_name}</td>
                    <td style={{width: "7%"}}>{dispatch.patient_mobile}</td>
                    {/*<td style={{width: "7%", textAlign: "right"}}>{(dispatch.hasOwnProperty('study_receipt') && dispatch.study_receipt !== null) ? dispatch.study_receipt[0].bill_amt : "0.00"}</td>
                    <td style={{width: "10%", flexDirection: "row", textAlign: "right"}}>{(dispatch.hasOwnProperty('study_receipt') && dispatch.study_receipt !== null) ? dispatch.study_receipt[0].received_amt : "0.00"}</td>
                    <td style={{width: "8%", textAlign: "right"}}>{(dispatch.hasOwnProperty('study_receipt') && dispatch.study_receipt !== null) ? dispatch.study_receipt[0].balance_amt : "0.00"}</td>*/}
                    <td style={{width: "7%", textAlign: "right"}}>{dispatch.bill_amt}</td>
                    <td style={{width: "10%", flexDirection: "row", textAlign: "right"}}>{dispatch.received_amt}</td>
                    <td style={{width: "8%", textAlign: "right"}}>{dispatch.balance_amt}</td>

                    <td style={{width: "10%", flexDirection: "row", textAlign: "left"}}>
                      {dispatch.disp_status}
                      {/*
                      {curSelRowRadio === idx &&
                      <>
                       &nbsp;&nbsp;
                      <BsPencilSquare
                        size={18}
                        style={{ color: "blue", cursor: "pointer"}}
                        onClick={(e) =>  handleStatusEdit(e, 'notes')}
                        name={`composite-edit-icon-${idx}`}
                        id={`composite-edit-icon-${idx}`}
                        />
                       </>
                      }
                       */}
                    </td>
                    
                    <td style={{width: "3%", flexDirection: "row", textAlign: "left"}}>
                      <BsCardText
                        size={18}
                        style={{ color: "blue", cursor: "pointer"}}
                        onClick={(e) =>  handleModalEdit(e)}
                        name={`modal-edit-icon-${idx}`}
                        id={`modal-edit-icon-${idx}`}
                        />
                      
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
                    
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Row>
              <br></br>
              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Study Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
              
              <hr align = "center"/>

              <Row>
               
            <Col xs={3} md={3} sm={3} lg={3}>
                  
            <Form.Group className="mb-3" >
             <Form.Label> Date</Form.Label>
                <Form.Control
                  type="date"
                  id="study_date_field"
                  name="study_date_field"
                  placeholder=" Date"
                  value={formValues.study_date_field}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                />
              </Form.Group>
              <p className="error-msg">{formErrors.study_date_field}</p>
            </Col>
                    
                  <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Reference</Form.Label>
                    <Select
                      id="ref_physician"
                      name="ref_physician"
                      value={{
                        value: physicianIdSelect.value,
                        label: physicianIdSelect.label,
                      }}
                      options={optPhysician}
                      defaultValue={{
                        value: physicianIdSelect.value,
                        label: physicianIdSelect.label,
                      }}
                      onChange={handlePhysicianSelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    <p className="error-msg">{formErrors.ref_doctorId}</p>
                  </Col>
                  
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Label>Study Type</Form.Label>
                    <Form.Group>

                      {optType.map((typeInd, idx) => (
                        <>
                          <Form.Check
                          disabled={isReadOnly}
                          inline
                          label={typeInd.label}
                          name="study_type"
                          type={"radio"}
                          id={`radio-type-${typeInd.value}`}
                          onChange={(e) => handleTypeRadioChange(e)}
                          //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                          checked={typeIdSelect.value === typeInd.value ? true : false}
                          />
                        </>
                      ))}
                    </Form.Group>
                  </Col>
                 
                  <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Study category</Form.Label>
                    <Select
                      id="study_id"
                      name="study_id"
                      value={{
                        value: studyIdSelect.value,
                        label: studyIdSelect.label,
                      }}
                      options={optStudy}
                      defaultValue={{
                        value: studyIdSelect.value,
                        label: studyIdSelect.label,
                      }}
                      onChange={handleStudySelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    <p className="error-msg">{formErrors.study_id}</p>
                  </Col>
                 
                </Row>
                <h7>Studies</h7>
                <br></br>
                <Row>
               
              {items.map((itemInd, idx) => (
              <>
              { studyIdSelect.value > 0 &&
                <Col xs={2} md={2} sm={2}>
                <div key={`item-div-key-${idx}`} className="field">
                  <Form.Check // prettier-ignore
                    key={`y-${idx}`}
                    id={itemInd.value}
                    //defaultChecked={"items" in formValues && formValues["items"].find(element => element.id === itemInd.value) !== undefined ? true : false }
                    //checked={"items" in formValues && formValues["items"].find(element => element.id === itemInd.value) !== undefined ? true : false }
                    checked={itemInd.checked_state}
                    name={`study-item-list-${idx}`}
                    type="Checkbox"
                    disabled={isReadOnly}
                    label={itemInd.label}
                    charges={itemInd.charges}
                    //charges={125.05}
                    //label={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? 'checked': productInd.name}
                    onChange={(e) => handleCheckBoxOnChange(e)}
                  />
                </div>
              </Col>
             }
             </>
              ))
              }
              
              {/*<p className="error-msg">{formErrors.items}</p>*/}
              </Row>

              <Row>
                    <Col xs={2} md={2} sm={2} lg={2}>
                      <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Report</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} accept=".png,.jpg,.jpeg,.pdf,.doc,.docx" />
                      </Form.Group>
                      {/*<FormControl id="formControlsFile" type="file" multiple label="File" />*/}
                    </Col>

                    
                    {selectedDocs.map((doc, idx) =>(
                      <Col xs={3} md={3} sm={3} lg={3}>
                      <Form.Label style={{paddingTop: '35px'}}> {doc.name}
                        &nbsp;
                      <BsFillXCircleFill
                                size={18}
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={(e) => handleDocItemDelete(e)}
                                name={`item-delete-icon-${idx}`}
                                id={`item-delete-icon-${idx}`}
                              />
                      </Form.Label>
                      </Col>
                    ))
                    }
                    {/*
                    <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: '30px'}}>
                    <Button onClick={handleUploadFile}>Submit</Button>
                    </Col>
                    */}
                  </Row>

                <br></br>
                <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Billing
                    </Badge>
                  </h4>
                </Col>
              </Row>

              <hr align = "center"/>
                <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Total Amount</Form.Label>
                  <Form.Control
                    type="text"
                    id="total_amt"
                    name="total_amt"
                    placeholder="Total amount"
                    value={formValues.total_amt}
                    onChange={(e) => handleValueChange(e)}
                  />
                  </Form.Group>
                </Col>
              
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Discount Amount</Form.Label>
                  <Form.Control
                    type="text"
                    id="discount_amt"
                    name="discount_amt"
                    placeholder="Discount amount"
                    value={formValues.discount_amt}
                    onChange={(e) => handleValueChange(e)}
                  />
                  </Form.Group>
                </Col>
                 
                
                  <Col xs={3} md={3} sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field required">Bill Amount</Form.Label>
                    <Form.Control
                     type="text"
                     id="bill_amt"
                     name="bill_amt"
                     placeholder="Bill amount"
                     value={formValues.bill_amt}
                     onChange={(e) => handleValueChange(e)}
                   />
                   </Form.Group>
                 </Col>
                </Row>
                </div>
                )}
          <div className="ui-form">
          {patientSelected && (
              <Row>          
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                    
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
               )}
              
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
              {(alertPurpose === 'confirmation-amount' || alertPurpose === 'confirmation-status') &&
                  <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
                }
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showAlertDialog}
              //backdrop="static"
              //keyboard={false}
              size="lg"
              onHide={handleStudyCloseDialog}
            >
              <Modal.Header closeButton>
                <Modal.Title>Study#  {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_no: ""}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Study Date:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_date: ""}
                      </Form.Label>
                      </Col>
                       
                  <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Study Id:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_no: ""}
                      </Form.Label>
                       </Col>
                       <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Study Type:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_type: ""}
                      </Form.Label>
                       </Col>

                       <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Study Category:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_Category: ""} 
                      </Form.Label>
                       </Col>       
                </Row>
                <Row>
                <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Reference:
                      </Form.Label>
                      &nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].physician: ""}
                      </Form.Label>
                       </Col>

                <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Patient Id:
                      </Form.Label>
                      &nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].patient_code: ""}
                      </Form.Label>
                       </Col>

                       <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Patient Name:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].patient_name: ""}
                      </Form.Label>
                       </Col>

                       <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Mobile:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].patient_mobile: ""}
                      </Form.Label>
                       </Col>
                
                </Row>
                <hr align = "center"/>
                <Row>
                <Row>
                
                <Col xs={6} md={6} sm={6} lg={6}className="d-inline-block" style={{borderRightWidth: 1}}>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h5 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Studies
                    </Badge>
                  </h5>
                </Col>
                {/*{selectedPatientRow >= 0 ? dispatchList[selectedPatientRow].study_receipt[0].receipt_no: ""}*/}
                { selectedPatientRow >= 0 && historyDispatchList[selectedPatientRow].study_items.map((study_item, idx) => (
                  <Col xs={12} sm={12} md={12} lg={12} >
                  <Form.Label style={{fontWeight: 'bold'}}>
                    {(idx + 1).toString() + ")"}
                    </Form.Label>
                    &nbsp;&nbsp;
                      <Form.Label>
                      {study_item.study_name} - Rs.{ study_item.charges} 
                    </Form.Label>
                     </Col>
                ))}
                
                </Col>
                
                <Col xs={6} md={6} sm={6} lg={6}>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h5 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Reports
                    </Badge>
                  </h5>
                </Col>

                {/*{ selectedPatientRow >= 0 && "study_art" in dispatchList[selectedPatientRow] && dispatchList[selectedPatientRow].study_art.map((study_art, idx) => (*/}
                { selectedPatientRow >= 0 && historyDispatchList[selectedPatientRow].study_art.map((study_art, idx) => (
                  <Col xs={12} sm={12} md={12} lg={12} >
                  <Form.Label style={{fontWeight: 'bold'}}>
                    {(idx + 1).toString() + ")"}
                    </Form.Label>
                    &nbsp;&nbsp;
                      <Form.Label>
                      {study_art.art_file_name} { (study_art.art_file_name.split('.').pop() === 'doc' || study_art.art_file_name.split('.').pop() === 'docx') ? 
                      <BsDownload
                      key={`file-download-${idx}`}
                      size={21}
                      style={{ cursor: "pointer", color: "blue" }}
                      name={`file-download-${idx}`}
                      onClick={(e) => handleDownloadFile(e)}
                      id={`file-download-${idx}`}
                      vb_file={study_art.art_file_path}
                    />
                      : 
                      <>
                      <BsDownload
                    key={`file-download-${idx}`}
                    size={21}
                    style={{ cursor: "pointer",color: "blue", }}
                    name={`file-download-${idx}`}
                    id={`file-download-${idx}`}
                    onClick={(e) => handleDownloadFile(e)}
                    vb_file={study_art.art_file_path}
                  />
                  &nbsp;
                  { (study_art.art_file_name.split('.').pop() === 'jpeg' || study_art.art_file_name.split('.').pop() === 'jpg' || study_art.art_file_name.split('.').pop() === 'png') ?
                      <BsCardImage
                      key={`file-download-${idx}`}
                      size={21}
                      style={{ color: "#1A87DE",cursor: "pointer" }}
                      name={`file-download-${idx}`}
                      id={`file-download-${idx}`}
                      onClick={(e) => handleViewFile(e)}
                      vb_file={study_art.art_file_path}
                    />
                    :
                      <BsFilePdfFill
                    key={`file-download-${idx}`}
                    size={21}
                    style={{ cursor: "pointer",color: "red", }}
                    name={`file-download-${idx}`}
                    id={`file-download-${idx}`}
                    onClick={(e) => handleViewFile(e)}
                    vb_file={study_art.art_file_path}
                  />
                  
                  }
                  </>
                  }
                    </Form.Label>
                     </Col>
                ))}
                {/*
                <Col xs={12} sm={12} md={12} lg={12} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      1:
                      </Form.Label>
                      &nbsp;&nbsp;
                        <Form.Label>
                        {formValues.receipt_date_study}
                      </Form.Label>
                       </Col>
                       <Col xs={12} sm={12} md={12} lg={12} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      2:
                      </Form.Label>
                      &nbsp;&nbsp;
                        <Form.Label>
                        {formValues.receipt_date_study}
                      </Form.Label>
                       </Col>
                       <Col xs={12} sm={12} md={12} lg={12} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      3:
                      </Form.Label>
                      &nbsp;&nbsp;
                        <Form.Label>
                        {formValues.receipt_date_study}
                      </Form.Label>
                       </Col>
                       */}
                </Col>
                </Row>
              </Row>
              <hr align = "center"/>
              
              <Col xs={6} md={6} sm={6} lg={6}>
                  <h5 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Receipt
                    </Badge>
                  </h5>
                </Col>
                
                <Row>
                <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Receipt No:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {(selectedPatientRow >= 0 && "study_receipt" in historyDispatchList[selectedPatientRow] && historyDispatchList[selectedPatientRow].study_receipt !== null) ? historyDispatchList[selectedPatientRow].study_receipt[0].receipt_no : ""}
                      </Form.Label>
                      </Col>
                       
                  <Col xs={4} sm={4} md={4} lg={4} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Receipt Date:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label style={{}}>
                        {(selectedPatientRow >= 0 && "study_receipt" in historyDispatchList[selectedPatientRow] && historyDispatchList[selectedPatientRow].study_receipt !== null) ? historyDispatchList[selectedPatientRow].study_receipt[0].receipt_date : ""}
                      </Form.Label>
                       </Col>
                       <Col xs={5} sm={5} md={5} lg={5} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Notes:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {(selectedPatientRow >= 0 && "study_receipt" in historyDispatchList[selectedPatientRow] && historyDispatchList[selectedPatientRow].study_receipt !== null) ? historyDispatchList[selectedPatientRow].study_receipt[0].notes : ""}
                      </Form.Label>
                       </Col>
                       </Row>

                  

                <Row>
                       <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Bill Amount:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {(selectedPatientRow >= 0 && historyDispatchList[selectedPatientRow].hasOwnProperty("study_receipt") && historyDispatchList[selectedPatientRow].study_receipt !== null && historyDispatchList[selectedPatientRow].study_receipt.length > 0) ? dispatchList[selectedPatientRow].study_receipt[0].bill_amt : ""}
                      </Form.Label>
                       </Col>
                  <Col xs={4} sm={4} md={4} lg={4} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Received Amount:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {(selectedPatientRow >= 0  && "study_receipt" in historyDispatchList[selectedPatientRow] && historyDispatchList[selectedPatientRow].study_receipt !== null) ? historyDispatchList[selectedPatientRow].study_receipt[0].received_amt: ""}
                      </Form.Label>
                       </Col>
                       
                  
                  <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Balance Amount:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {(selectedPatientRow >= 0  && "study_receipt" in historyDispatchList[selectedPatientRow] && historyDispatchList[selectedPatientRow].study_receipt !== null) ? historyDispatchList[selectedPatientRow].study_receipt[0].balance_amt: ""}
                      </Form.Label>
                       </Col>

                       
                </Row>  

                <hr align = "center"/>

                <Col xs={6} md={6} sm={6} lg={6}>
                  <h5 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Dispatch
                    </Badge>
                  </h5>
                </Col>
                <Row>
                <Col xs={4} sm={4} md={4} lg={4} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Dispatch Date:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_disp[0].disp_date: ""}
                      </Form.Label>
                       </Col>

                       <Col xs={5} sm={5} md={5} lg={5} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Received By:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_disp[0].receivedby: ""}
                      </Form.Label>
                       </Col>

                       <Col xs={3} sm={3} md={3} lg={3} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Mobile:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_disp[0].mobileno: ""}
                      </Form.Label>
                       </Col>
                       </Row>

                      <Row>
                       <Col xs={12} sm={12} md={12} lg={12} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Notes:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label>
                        {selectedPatientRow >= 0 ? historyDispatchList[selectedPatientRow].study_disp[0].notes: ""}
                      </Form.Label>
                       </Col>
                </Row>
              

                </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancelDialog}>Cancel</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
        )
           :
           <div className="container-spinner">
           <div className="vertical-center-spinner">
            <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
            
                   <span className="visually-hidden">Loading...</span>
                 </Spinner>
            </div>
            </div>
         }
       
      </Container>
  );
};

export default AddUpdateStudy;
