import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import { Table } from "react-bootstrap";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain.js";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config.js";
  import spinner_logo from '../assets/images/spinner_logo.png';
  import {formatDateIndian} from "./utils.js";
  
  
  function ViewPhysicianSummary() {
    const initialized = useRef(false);
    var navigate = useNavigate();
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);

    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);

    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
    function padTo2Digits(num) {
      //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
      var modNum = num.toString().padStart(2, "0");
      console.log("Modified  value : ", modNum);
      return modNum;
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }
  
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
    const intialValues = {
      //date_from: formatDate(new Date(firstDay)),
      date_from: formatDate(new Date()),
      date_to: formatDate(new Date()),
    }

    let customerArr = [
  
      { value: 1, label: "All Customers" },
      { value: 2, label: "Deppam Motors" },
      { value: 3, label: "ABC Pumps" },
      { value: 4, label: "ADP Compressors" },
    ];
  
    let physicianArr = [
      {
      physician: "Arun",
      study_type: "Scan",
      total_studies: "3",
      total_amt: "20000",
      },

      { 
        physician: "Farina",
        study_type: "Lab",
        total_studies: "2",
        total_amt: "20000",
        },

        { 
          physician: "Joy",
          study_type: "Scan",
          total_studies: "3",
          total_amt: "10000",
          },
      
    ];
  
   
    
    
  
    const [physicianList, setPhysicianList] = useState(physicianArr);
    const [taxSummary, setTaxSummary] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [isSearched, setIsSearched] = useState(false);
    const [optCustomer, setOptCustomer] = useState(customerArr);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");
    const [formErrors, setFormErrors] = useState({});
    var validateErr = false;
   
  
   
    /*
    const getCustomerList = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ..
          .client.headers,Authorization: `Bearer ${aIVal}`}
      
  
        var res = await axios.get(
          client.domain + "/crm/getAllCustomers",
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setCustomerList(res.data.detail.data);
          setCustomerListFull(res.data.detail.data);
          setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
    };
    */
   
    
    /*
    const getTaxList = async () => {
      var retStateLoad = false;
      var taxRet = [];
      var summaryRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
  
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current,
          date_from: formValues["date_from"],
          date_to: formValues["date_to"]
        };
  
        var res = await axios.post(client.domain + "/bms/getTaxSummaryList", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setTaxList(res.data.detail.data.tax_details);
          setTaxSummary(res.data.detail.data.tax_total[0]);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          taxRet = res.data.detail.data.tax_details;
          summaryRet = res.data.detail.data.tax_total;
          retStateLoad = true;
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, taxRet, summaryRet];
    };
    */
   
   
    useEffect(() => {
      if (!initialized.current) {
        console.log("*************** useEffect::ViewPhysicianSummary called ##########################");
        /*
        ( async() => {
          var [retStateLoad, customerRet] = await getTaxList();
        })();
        */
        //getTaxList();
        setToRender(true);
        initialized.current = true;
       
      }
      
    }, []);
   /*
    const handleAdd = async (e) => {
      console.log("handle add customers called : ", e);
      navigate("/addUpdateStockIn", {
        state: { passed_element: [], action: "add" },
      });
    };
    */
  
    
    
   
    
    const handleTaxPrint = async (e) => {
     //navigate("/printCustomer")
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(
        urlStr.lastIndexOf(client.urlDelimiter) + 1
      );
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
  
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printTaxSummary?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
      
    };
   
    const onChangeStartDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_from: e.target.value });
    };
  
    const onChangeEndDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_to: e.target.value });
    };
  

    const validate = (values) => {
      console.log("validateErr before start processing : ", validateErr);
      console.log("Validate called ...", values);
      const errors = {};
      const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
     
      console.log("VALIDATE ERROR inside validate func : ", validateErr);
      return errors;
    };
  
    const handleSearch = async (e) => {
      e.preventDefault();
      console.log("handle search called : ", e);
      /*
      validateErr = false;
      setFormErrors(validate(formValues));
      setIsSubmit(true);
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr === false) {
        console.log("FORM VALUES at handleSubmit: ", formValues);
      }
  
      if (formValues.code) {
        const searchedElement = patientList.find((ele) => ele.code === formValues.code);
        console.log("searched element : ", searchedElement);
        setPatientList([searchedElement]);
      }
  
      if (formValues.name) {
        //const searchedElement = patientList.find((ele) => ele.name.toUpperCase() === formValues.name.toUpperCase());
        const searchedElement = patientList.find((ele) => ele.name.toUpperCase().includes(formValues.name.toUpperCase()));
        var patientListMod = [];
        patientList.forEach(function(patientInd){
        //console.log("searched element : ", searchedElement);
        if (patientInd.name.toUpperCase().includes(formValues.name.toUpperCase())){
           patientListMod.push(patientInd)
        }
       })
       console.log("FILETRED ARRAY : ", patientListMod);
  
       if ( patientListMod.length > 0 ) {
        setPatientList(patientListMod);
       }
      }
  
      if (formValues.mobile_primary) {
        const searchedElement2 = patientList.find((ele) => ele.mobile_primary === formValues.mobile_primary);
        console.log("searched element : ", searchedElement2);
        setPatientList([searchedElement2]);
      }
  
      if (formValues.ref_no) {
        const searchedElement2 = patientList.find((ele) => ele.ref_no === formValues.ref_no);
        console.log("searched element : ", searchedElement2);
        setPatientList([searchedElement2]);
      }
      */
    };
  
    const handleClearSearch = (e) => {
      console.log("handle  clear search called : ", e);
      //setPatientList(patientsListOrig);
      //setFormValues(intialValues);
      //setPatientList(patientListFull);
    };
  
    const handleValueChange = (e) => {
      //e.preventDefault();
  
      console.log("Clicked value : ", e);
      const { name, value } = e.target;
      console.log("Captured input element name : ", name);
      console.log("Captured input element value : ", value);
      setFormValues({ ...formValues, [name]: value });
  
      //console.log("Clicked data : ", data);
    };

    const sendDataToParent  = (passedBackValue) => {
      console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
      if ( passedBackValue.hasOwnProperty("business_id") ) {
        currSelBusinessRef.current = passedBackValue["business_id"];
        //navigate(0);
      }
      else if (passedBackValue.hasOwnProperty("branch_id")) {
        currBranchRef.current = passedBackValue["branch_id"];
      }
      else {
        // Future implementation
      }
      //setCurrSelBusinessId(passedBackValue);
      
      //getBranchList();
    };
  
   
    return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
        <Row>
            <Col xs={10} md={10} sm={10} lg={10}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>{menuSettings.permissions.find(element => (element.code === 'viewStudies' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'viewStudies')).page_title : "Physician Summary"}</span>
            </Col>
            
            <Col
              xs={2}
              md={2}
              sm={2}
              lg={2}
              className="d-flex justify-content-end float-end">
              <Button id="taxPrint" name = "taxPrint"  onClick={(e)  => handleTaxPrint(e)}
              //disabled={menuSettings.permissions.find(element => (element.code === 'viewStudies' && element.a_print === true && studyList.length > 0)) ? false : true}
              >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-tax-icon`}
                    id={`print-tax-icon`}
                    
                  />
                </Button>
               
                
            </Col>
            </Row>
            <hr align="center" />
          <Row>
          <Col xs={3} md={3} sm={3} lg={3}>
            <Row>
          
          <Col>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="physician"
              id="physician"
              placeholder="Physician"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.physician}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.physician}</p>
          </Col>          
          </Row>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <Row>
              <Col>
                <Form.Control
                  size="xs"
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
            
                <Col>
                  <Form.Label style={{ textAlign: "center", paddingTop: "5px" }}>To</Form.Label>
                </Col>
        
          
            <Col>
                <Form.Control
                  size="xs"
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                </Col>

                 </Row>
                 
                 </Col>
                 <Col xs={1} md={1} sm={1} lg={1}>
                  <Button
                    variant="warning"
                    id="btn-search-id"
                    name="btn-search-name"
                    type="submit"
                    onClick={handleSearch}
                  >
                      Search
                  </Button>
                </Col>
          
          </Row>
          <hr align="center" />
          <Row>

                <Col xs={12} md={12} sm={12} lg={12} style={{ textAlign: "right" }}>
               
                  <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                    Total Amount :
                  </Form.Label>
                  <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                      50,000.00
                  {/*  
                    {taxSummary.tax_net_diff > 0.01 ?
                    <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.tax_net_diff).toFixed(2)))}
                    </>
                    : (0.00).toFixed(2)}
                  */}
                  </Form.Label>
                <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                </Col>
              </Row>
  

            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center", width: "5%"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "60%"}}>Physician</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>study Type</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Total Studies</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "15%"}}>Total Amount</th> 
                </tr>
              </thead>
              <tbody>
                {physicianList.map((physician, idx) => (
                  <tr>
                    <td style={{textAlign: "right", width: "5%"}}>{idx + 1}</td>
                    <td style={{textAlign: "left", width: "60%"}}>{physician.physician}</td>
                    <td style={{textAlign: "left", width: "10%"}}>{physician.study_type}</td>
                    <td style={{textAlign: "left", width: "10%"}}>{physician.total_studies}</td>
                    <td style={{textAlign: "right", width: "15%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(physician.total_amt).toFixed(2)))}</td>
                  </tr>
                  
                ))}
               
              </tbody>
            </Table>
          
          </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }      
       </Container>
     
    );
  }
  export default ViewPhysicianSummary;