
import React, { Fragment, useState } from 'react';
import { Alert } from 'react-bootstrap';
import logo_avn from './assets/images/logo_avs.png';

import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from "./pages/Auth.js";
import CsvGenerate from './pages/CsvGenerate.js';


import { client } from './config/Config.js';
import axios from 'axios';
import SplashScreen from './pages/SplashScreen.js';

import ListPatients from './pages/ListPatients.js';
import AddUpdatePatient from './pages/AddUpdatePatient.js';

import ListPhysicians from './pages/ListPhysicians.js';
//import AddUpdatePhysician from './pages/AddUpdatePhysician';
import AddUpdatePhysician from './pages/AddUpdatePhysician.js';

import ListBusiness from './pages/ListBusiness.js';
import AddUpdateBusiness from './pages/AddUpdateBusiness.js';

import ListBranches from './pages/ListBranches.js';
import AddUpdateBranch from './pages/AddUpdateBranch.js';

import ListDocSettings from './pages/ListDocSettings.js';
import AddUpdateDocSetting from './pages/AddUpdateDocSetting.js';

import MyProfile from './pages/MyProfile.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';

import AddUpdateStudyItemCat from './pages/AddUpdateStudyItemCat.js';
import ListStudyItemCategories from './pages/ListStudyItemCategories.js';

import ListStudyItems from './pages/ListStudyItems.js';
import AddUpdateStudyItem from './pages/AddUpdateStudyItem.js';

import ViewStudies from './pages/ViewStudies.js';
import ViewPhysicianSummary from './pages/ViewPhysicianSummary.js';
import ViewStudySummary from './pages/ViewStudySummary.js';
import ViewCaseStudies from './pages/ViewCaseStudies.js';
import ViewcsbyPhysician from './pages/ViewcsbyPhysician.js';
import ViewcsbyStudy from './pages/ViewcsbyStudy.js';
import ViewReceipts from './pages/ViewReceipts.js';
import ListItems from './pages/ListItems.js';
import AddUpdateItem from './pages/AddUpdateItem.js';
import ListItemCategories from './pages/ListItemCategories.js';
import AddUpdateItemCategory from './pages/AddUpdateItemCategory.js';
import AddUpdateStudyProgress from './pages/AddUpdateStudyProgress.js';
import AddUpdateServiceTransV3 from './pages/AddUpdateServiceTransV3.js';
import AddUpdateStudy from './pages/AddUpdateStudy.js';
import PrintReceipt from './pages/PrintReceipt.js';




//import { useNavigate } from "react-router-dom";

function App() {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');

  //const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);


    let reqLoginPayload = {
      username: username.value,
      password: password.value
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var res = await axios.post(client.domain + '/login', reqLoginPayload, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.res_data);
        console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        console.log("Response message from server aI : ", res.data.detail.res_data.aI);

        localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setShowMsg(error.response.data.detail.res_data);
          setShowMsgTitle('LOGIN FAILED!');
          setShow(true);
        }
      }
    }

  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/auth" element={<Auth />} />
        
        <Route path="/listPhysicians" element={< ListPhysicians />} />
        <Route path="/addUpdatePhysician" element={< AddUpdatePhysician />} />
        <Route path="/listPatients" element={< ListPatients />} />
        <Route path="/addUpdatePatient" element={< AddUpdatePatient />} />
        <Route path="/privacyPolicy" element={< PrivacyPolicy />} />
        <Route path="/listBusiness" element={< ListBusiness />} />
        <Route path="/addUpdateBusiness" element={< AddUpdateBusiness />} />
        <Route path="/listBranches" element={< ListBranches />} />
        <Route path="/addUpdateBranch" element={< AddUpdateBranch />} />
        <Route path="/myProfile" element={< MyProfile />} />
        <Route path="/listDocSettings" element={< ListDocSettings />} />
        <Route path="/addUpdateDocSetting" element={< AddUpdateDocSetting />} />
        <Route path="/listStudyItems" element={< ListStudyItems />} />
        <Route path="/addUpdateStudyItem" element={< AddUpdateStudyItem />} />
        <Route path="/listStudyItemCategories" element={< ListStudyItemCategories />} />
        <Route path="/addUpdateStudyItemCat" element={< AddUpdateStudyItemCat />} />
        <Route path="/viewStudies" element={< ViewStudies />} />
        <Route path="/viewPhysicianSummary" element={< ViewPhysicianSummary />} />
        <Route path="/viewStudySummary" element={< ViewStudySummary />} />
        <Route path="/viewCaseStudies" element={< ViewCaseStudies />} />
        <Route path="/viewcsbyPhysician" element={< ViewcsbyPhysician />} />
        <Route path="/viewcsbyStudy" element={< ViewcsbyStudy />} />
        <Route path="/viewReceipts" element={< ViewReceipts />} />
        <Route path="/listItems" element={< ListItems />} />
        <Route path="/addUpdateItem" element={< AddUpdateItem />} />
        <Route path="/listItemCategories" element={< ListItemCategories />} />
        <Route path="/addUpdateItemCategory" element={< AddUpdateItemCategory />} />
        <Route path="/addUpdateStudyProgress" element={< AddUpdateStudyProgress />} />
        <Route path="/addUpdateServiceTransV3" element={< AddUpdateServiceTransV3 />} />
        <Route path="/addUpdateStudy" element={< AddUpdateStudy />} />
        <Route path="/printReceipt" element={< PrintReceipt />} />

      </Routes>
    </HashRouter>
  );
}
export default App;
