import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal, LS_MSG_TAG } from "../config/Config.js";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
  BsFillPrinterFill
} from "react-icons/bs";
import SidebarMain from "./SidebarMain.js";
import {formatDateIndian, getDistrictList, getStateList, getCustomerMessages } from "./utils.js";
import spinner_logo from '../assets/images/spinner_logo.png';



//function AddUpdateCustomer () {
const AddUpdateStudyProgress = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  
  let initialItems = [];
  const intialValues = {
   name: "",
   mobile: "",
   cs_id: 0,
   bill_amt_study: 0.00,
   received_amt_study: 0.00,
   balance_amt_study: 0.00,
   notes_study: "", 
   receipt_no_study: 0,
   receipt_date_study: formatDate(new Date()),
   //disp_date: formatDateIndian(new Date())
   disp_date: formatDate(new Date()), 
  };

 /* 
  var dispatchArr = [
    {
      SNo: 1,
      study_type: "Scan",
      study_id: "BS001",
      study_date: "12-06-2024",
      ref_physician: "Abi",
      patient_id: "PS001",
      patient_name: "Sam",
      patient_mobile: "9081234567",
      bill_amount: "300.00",
      received_amount: "800.00",
      balance_amount: "600.00",
      report_status: "Active"
    },

    {
      SNo: 2,
      study_type: "Scan",
      study_id: "BS002",
      study_date: "10-08-2024",
      ref_physician: "John",
      patient_id: "PS002",
      patient_name: "Arul",
      patient_mobile: "9381234588",
      bill_amount: "3360.00",
      received_amount: "7600.00",
      balance_amount: "6650.00",
      report_status: "Active"
    },
    
    {
      SNo: 3,
      study_type: "Scan",
      study_id: "BS003",
      study_date: "11-06-2024",
      ref_physician: "Geetha",
      patient_id: "PS003",
      patient_name: "Jaanu",
      patient_mobile: "90812345876",
      bill_amount: "300.00",
      received_amount: "800.00",
      balance_amount: "600.00",
      report_status: "Active"
    },
  ];
  */
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [alertPurpose, setAlertPurpose] = useState('init');

  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  const [submitAction, setSubmitAction] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checkState, setCheckState] =  useState(false);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const[optType, setOptType] = useState([]);
  const [typeIdSelect, setTypeIdSelect] = useState({});
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [dispatchList, setDispatchList] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [selectedRadio, setSelectedRadio] = useState(-1);
  const [isEditable, setIsEditable] =  useState(false);
  const [statusState, setStatusState] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [editAction, setEditAction] = useState("amount_edit");
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save");

  var validateErr = false;
  const [curSelRowRadio, setCurSelRowRadio] = useState(-1);

  const validateSearch = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name && !values.mobile) {
      errors.name = "Please enter valid Details!";
      validateErr = true;
    }
    else {
      errors.name = "";
    }
  
  
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    errors.received_amt_study = "";
    errors.mobileno_disp = "";
    errors.receivedby_disp = "";
    
    if ( editAction === 'amount_edit'){
    if (!values.received_amt_study || values.received_amt_study < 0.01) {
      errors.received_amt_study = "Received Amount is required!";
      validateErr = true;
    }
    else {
      errors.received_amt_study = "";
    }
  }
  else if (editAction === 'status_edit'){
    if (!values.mobileno_disp) {
      errors.mobileno_disp = "Mobile Number is required!";
      validateErr = true;
    }
    else if (values.mobileno_disp && values.mobileno_disp.length !== 10){
      errors.mobileno_disp = "Should be a Valid Mobile Number!";
      validateErr = true;
    }
    else {
      errors.mobileno_disp = "";
    }

    if (!values.receivedby_disp) {
      //errors.received_amt_study = getCustomerMessages(LS_MSG_TAG,"MSG_ITEMCAT_NAME_MANDATORY");
      errors.receivedby_disp = "ReceivedBy is required!";
      validateErr = true;
      //setValidateError(true);
    }
    else {
      errors.receivedby_disp = "";
    }
  }

    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(true);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };

  const CallStudyListData = async () => {
    console.log("+++++++++++++ ********** CallStudyListData method called ...");
    var retStateLoad = false;
    var studyRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current,
        brid:currBranchRef.current,
        name: formValues["name"],
        mobile: formValues["mobile"]
      };

      var res = await axios.post(client.domain + "/bms/getPatientCaseStudies", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail);
        setDispatchList(res.data.detail.data.casestudy);
        setIsSearched(true);
        setToRender(true);

        studyRet = res.data.detail.data.casestudy;
        console.log("<<<<<<<<< StudyRet >>>>>>>>>>>>>>", studyRet);
        retStateLoad = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, studyRet];
  };

  /*

  const callCategoryDetById = async(categoryIdPassed) => {
    var retState = false;
    var categoryInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var reqParams = {
        id: categoryIdPassed
      };

    var res = await axios.post(client.domain + "/bms/getItemCategoryDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
       
        setFormValues(res.data.detail.data[0]);
        categoryInfoRet = res.data.detail.data[0];
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);   
        }
      }
      setSubmitAction(false);
    }
    return [retState, categoryInfoRet];   
  };

  */
  
  useEffect(() => {
    if (!initialized.current) {
      console.log( "*************** useEffect::addUpdatePatient called ##########################");
      
      (async () => {
        //var [retStateLoad, typeRet] = await CallStudyListData();
        
        //setIsReadOnly(true)
       setToRender(true);
          
      })(); 
      initialized.current = true;
    }
  }, []);

 
  const handlePostSaveOk = (e) => {
    console.log("user confirmed to customer added ...");
    setShowAlert(false);
    if( alertPurpose === "save"){
      //navigate(0);
      setIsEditable(false);
      setStatusState(false);
      setIsSearched(true);
      //CallStudyListData();
      //navigate(0);
      //navigate("/listCustomers");
      setSelectedRadio(-1);
      setCurSelRowRadio(-1);

      var dispatchListNew = dispatchList.slice();
      setDispatchList(dispatchListNew);
      console.log("SaveOk action called for dialog confirm **************************");
      navigate(0);
    }

    else if (alertPurpose === 'confirmation-status'){ 
     setIsEditable(false);
     setStatusState(true);
    }

    else if (alertPurpose === 'confirmation-amount'){ 
      setStatusState(false);
      setIsEditable(true);
     }
    else{
    console.log("Action called for dialog confirm **************************");
    //navigate();
    }
  };

  const handleCloseDialog = () => {
    setShowAlert(false);
    if (alertPurpose === 'confirmation-status'){ 
      setStatusState(false);
      setIsEditable(true);
     }

      else if (alertPurpose === 'confirmation-amount'){ 
        setIsEditable(false);
        setStatusState(true);
     }
    //navigate(0);
  };

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    console.log("FORM VALUES at handleSubmit: ", formValues);
    validateErr = false;
    setFormErrors(validate(formValues));
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
    if (validateErr) {
      return -1;
    }

    var apiToCall = "";
    //if (state.action === "add") {
    if ( editAction === 'status_edit' ) {
      apiToCall = "/bms/updDispatch";
    }
    else if (editAction === 'amount_edit'){
      apiToCall = "/bms/addupdReceipt";
    }
    //} 
    //else {
      //apiToCall = "/bms/updDispatch";
    //}

    var reqParam = {};

   if(editAction === 'status_edit' ) {
      reqParam = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current,
        cs_id: dispatchList[selectedRadio].cs_id,
        disp_date:formValues["disp_date"],
        receivedby:formValues["receivedby_disp"],
        mobileno: formValues["mobileno_disp"],
        notes: formValues["notes_disp"]
      };
    }
    else if ( editAction === 'amount_edit'){
      reqParam = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current,
        //cs_id:formValues["cs_id"],
        cs_id: dispatchList[selectedRadio].cs_id, 
        receipt_date: formValues["receipt_date_study"],
        bill_amount: formValues["bill_amt_study"],
        received_amount: formValues["received_amt_study"],
        balance_amount: formValues["balance_amt_study"],
        notes: formValues["notes_study"]
      };
    }
    /*
    var reqParam = {
      bid: currSelBusinessRef.current,
      brid: currBranchRef.current,
      cs_id:formValues["cs_id"],
      disp_date:formValues["disp_date"],
      receivedby:formValues["receivedby"],
      mobileno: formValues["mobileno"],
      notes: formValues["notes"]
    };
    */

     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-add') {
         setSubmitAddAction(true);
         submitActionRef.current = "save-add";
       }
       else {
         setSubmitAction(true);
         submitActionRef.current = "save";
       }
       var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
       //var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.data);
         console.log("ACTION VALUE PASSED++++++++++++++++++++++++++-------------: ", actionVal);
         if ( editAction === 'status_edit' ) {
          /*
          var submittedRow = dispatchList[selectedRadio];
          submittedRow.receivedby = formValues.receivedby_disp;
          submittedRow.mobileno = formValues.mobileno_disp;
          submittedRow.notes = formValues.notes_disp;
          submittedRow.disp_date = formValues.disp_date;
          var dispatchListNew = dispatchList.slice();
          dispatchListNew[selectedRadio] = submittedRow;
          setDispatchList(dispatchListNew);
          */
          await CallStudyListData();
         }
         if ( actionVal === 'save-add' ) {
           setSubmitAddAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertPurpose("save")
         setAlertTitle("Success");
         //setShowAlert(true);
         setSubmitAction(false);
         
         if(editAction === 'status_edit' ) {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_DISPATCH_UPDATED"));
         }
         else {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_RECEIPT_UPDATED"));
         }
        
           setShowAlert(true);
          
       }
       else {
        setAlertPurpose("failed")
        setAlertTitle("FAILED!");
         setShowAlert(true);
         setSubmitAction(false);
         
         if(editAction === 'status_edit' ) {
           setAlertBody("Dispatch Update Failed!");
         }

         else{
          setAlertBody("Receipt Update Failed!")
         }
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       setSubmitAction(false);
     }
     
  };
  
    const handleCheckBox = async (e) => {
      console.log("handle copy to billing called :", e);
      console.log("handle copy to billing called CHECKED :", e.target.checked); 
      setCheckState(e.target.checked);   
    };

    const handleRadioChange = async (e) => {
      console.log("Value button clicked : ", e);
      const { name, id } = e.currentTarget;
      console.log("Captured value name at handleRadioChange : ", name);
      console.log("Captured value id at handleRadioChange : ", id);
      var currentRowName = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element at handleRadioChange : ", nameOfElement);
      console.log("Current row id at handleRadioChange : ", currentRowName);
      //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
      //setTypeIdSelect({ value: parseInt(currentRowName), label: e.currentTarget.label })
      //setTypeIdSelect({ value: ["currentRowName"], label: e.currentTarget.label })
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      console.log("handle search called : ", e);
      validateErr = false;
      setFormErrors(validateSearch(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        console.log(">>>>>>>>>>>>>>>>> VALIDATE FAILED FOR SEARCH SUBMIT <<<<<<<<<<<<");
        return 0;
      }
      //setIsSearched(true);
      setToRender(false);
      //setIsSubmit(true);
      
      
      await CallStudyListData();
  
      /*
      if (formValues.name) {
        //const searchedElement = patientList.find((ele) => ele.name.toUpperCase() === formValues.name.toUpperCase());
        const searchedElement = dispatchList.find((ele) => ele.name.toUpperCase().includes(formValues.name.toUpperCase()));
        var dispatchListMod = [];
        dispatchList.forEach(function(dispatchInd){
        //console.log("searched element : ", searchedElement);
        if (dispatchInd.name.toUpperCase().includes(formValues.name.toUpperCase())){
           dispatchListMod.push(dispatchInd)
        }
       })
       console.log("FILETRED ARRAY : ", dispatchListMod);
  
       if ( dispatchListMod.length > 0 ) {
        setDispatchList(dispatchListMod);
       }
      }
  
      if (formValues.mobile) {
        const searchedElement2 = dispatchList.find((ele) => ele.mobile === formValues.mobile);
        console.log("searched element : ", searchedElement2);
        setDispatchList([searchedElement2]);
      }
      */
  
      
    };

    const handlePrintReceipt = async () => {
      console.log("Handle print branch called ...");
      //navigate("/printReceipt")
      
  
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
  
      var payment_for = "";

      var study_item_list = "";
      dispatchList[selectedRadio].study_items.map((study_item) =>{
        study_item_list = study_item_list + study_item.study_name;
      })
      console.log("STUDY ITEM LIST : ", study_item_list);
      payment_for = dispatchList[selectedRadio].study_type + " - " + study_item_list;

      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printReceipt?receipt_no=${formValues.receipt_no_study}&receipt_date=${formValues.receipt_date_study}&bill_amt=${formValues.bill_amt_study}&received_amt=${formValues.received_amt_study}&balance_amt=${formValues.balance_amt_study}&patient_name=${dispatchList[selectedRadio].patient_name}&payment_for=${payment_for}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0, 0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
    };
  
    const handleClearSearch = (e) => {
      console.log("handle  clear search called : ", e);
      navigate(0)
      //setPatientList(patientsListOrig);
      //setFormValues(intialValues); 
      //setPatientList(patientListFull);
    };

    const handleAmountEdit = (e) => {
      console.log("handle  Amount search called : ", e); 
      console.log("++++++++++++++++++ dispatchList ^^^^^^^^^^^^^^^^ : ", dispatchList[selectedRadio]);
      //console.log("Study Receipt : ", dispatchList[selectedRadio].study_receipt[0]);
      //if(dispatchList[selectedRadio].hasOwnProperty("study_receipt") && dispatchList[selectedRadio].study_receipt[0] !== null && dispatchList[selectedRadio].study_receipt[0].length  ){   
      if(dispatchList[selectedRadio].hasOwnProperty("study_receipt") && dispatchList[selectedRadio].study_receipt !== null && dispatchList[selectedRadio].study_receipt.length > 0 ){
        console.log("<<<<<<<<<<<<<<<<<< INSIDE ASSIGNMENT <<<<<<<<<<<< "); 
        setFormValues({ ...formValues, bill_amt_study: dispatchList[selectedRadio].study_receipt[0].bill_amt, received_amt_study: dispatchList[selectedRadio].study_receipt[0].received_amt, balance_amt_study: dispatchList[selectedRadio].study_receipt[0].balance_amt, notes_study: dispatchList[selectedRadio].study_receipt[0].notes, receipt_no_study: dispatchList[selectedRadio].study_receipt[0].receipt_no, receipt_date_study: dispatchList[selectedRadio].study_receipt[0].receipt_date});
      }
      else {
        setFormValues({ ...formValues, bill_amt_study: dispatchList[selectedRadio].bill_amt, received_amt_study: dispatchList[selectedRadio].received_amt, balance_amt_study: dispatchList[selectedRadio].balance_amt, notes_study: "", receipt_no_study: "", receipt_date_study: formatDate(new Date())});
      }
      
      if ( !statusState ) {
        setIsEditable(true);
      }
      
      if ( statusState ) {
        setShowAlert(true);
        setAlertPurpose("confirmation-amount");
        setAlertTitle("Confirmation");
        setAlertBody("Dispatch Details Will be Cleared.Continue to proceed");
        //setStatusState(false);
      }
      setEditAction("amount_edit");
      
    };

    const handleStatusEdit = (e) => {
      console.log("handle  Status search called : ", e);
      console.log("Date from response : ", dispatchList[selectedRadio].study_disp[0]);
      // , disp_date:dispatchList[selectedRadio].study_disp[0].disp_date
      setFormValues({ ...formValues, 
        receivedby_disp:dispatchList[selectedRadio].study_disp[0].receivedby ? dispatchList[selectedRadio].study_disp[0].receivedby : "", 
        mobileno_disp:dispatchList[selectedRadio].study_disp[0].mobileno ? dispatchList[selectedRadio].study_disp[0].mobileno : "", 
        notes_disp:dispatchList[selectedRadio].study_disp[0].notes ? dispatchList[selectedRadio].study_disp[0].notes : "", 
        disp_date:dispatchList[selectedRadio].study_disp[0].disp_date ? formatDate(new Date(dispatchList[selectedRadio].study_disp[0].disp_date)) : formatDate(new Date())});
      
      if ( !isEditable ) {
        setStatusState(true);
      }
      if ( isEditable ) {
        setShowAlert(true);
        setAlertPurpose("confirmation-status");
        setAlertTitle("Confirmation");
        setAlertBody("Receipt Details Will be Cleared.Continue to proceed");
        //setIsEditable(false);
      }
      setEditAction("status_edit");
      
    };
 
    const handleValueChange = (e) => {
      //e.preventDefault();
      console.log("Clicked value : ", e);
      const { name, value } = e.target;
      console.log("Captured input element name : ", name);
      console.log("Captured input element value : ", value);
      if (name === "bill_amt_study" || name === "received_amt_study" || name === "balance_amt_study" || name === "mobileno_disp" || name === "mobile" )  {
        console.log("Inside check ******************");
        if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
          var balance_amt_temp = 0.00;
          console.log("<<<<<<<<<<<<<<< AFTER CONDITION MET >>>>>>>>>>>>> : ", e.currentTarget.value);
          if ( name === 'bill_amt_study') {
            //var igst_per_tmp = 0.00;
            console.log("CGST CONVERSION at cgst check : ", parseFloat(value));
            console.log("SGST CONVERSION at cgst check : ", parseFloat(formValues["received_amt_study"]));
            if ( !isNaN(parseFloat(value)) && !isNaN(parseFloat(formValues["bill_amt_study"])) ) {
              balance_amt_temp = (parseFloat(value) - parseFloat(formValues["received_amt_study"])).toFixed(2);
            }
            else if( isNaN(parseFloat(value)) && !isNaN(parseFloat(formValues["received_amt_study"])) ) {
              balance_amt_temp = parseFloat(formValues["received_amt_study"]);
            }
            else if( !isNaN(parseFloat(value)) && isNaN(parseFloat(formValues["received_amt_study"])) ) {
              balance_amt_temp = parseFloat(value);
            }
            else {
              console.log("ELSE CALLED at cgst check ?????????????????????????");
              balance_amt_temp = 0.00;
            }
          }
          else if ( name === 'received_amt_study' ) {
            
            console.log("BILL AMOUNT STUDY AT handleValueChange : ", parseFloat(formValues["bill_amt_study"]));
            console.log("RECEIVED AMOUNT AT handleValueChange : ", parseFloat(value));
            if ( !isNaN(parseFloat(formValues["bill_amt_study"])) && !isNaN(parseFloat(value)) ) {
              console.log("<<<<<<<<<<<<<<<<< IGST VALUE UPDATED >>>>>>>>>>>>>>>>>", (parseFloat(formValues["bill_amt_study"]) - parseFloat(value)).toFixed(2));
              balance_amt_temp = (parseFloat(formValues["bill_amt_study"]) - parseFloat(value)).toFixed(2);
            }
            else if ( !isNaN(parseFloat(formValues["bill_amt_study"])) && isNaN(parseFloat(value)) ) {
              balance_amt_temp = parseFloat(formValues["bill_amt_study"]);
            }
            else if ( isNaN(parseFloat(formValues["bill_amt_study"])) && !isNaN(parseFloat(value)) ) {
              balance_amt_temp = parseFloat(value);
            }
            else {
              console.log("ELSE CALLED at sgst check ?????????????????????????");
              balance_amt_temp = 0.00;
            }
            
            console.log("<<<<<<<<<<<<<< RECEIPT LENGTH >>>>>>>>>> : ", dispatchList[selectedRadio].study_receipt);
            var selectDispatchReceipt = {};
            if (dispatchList[selectedRadio].hasOwnProperty("study_receipt") && dispatchList[selectedRadio].study_receipt !== null && dispatchList[selectedRadio].study_receipt.length > 0 ) {
              selectDispatchReceipt = dispatchList[selectedRadio].study_receipt[0];
              selectDispatchReceipt.bill_amt = dispatchList[selectedRadio].study_receipt[0].bill_amt;
              selectDispatchReceipt.received_amt = value ? parseFloat(value) : dispatchList[selectedRadio].study_receipt[0].received_amt;
            }
            else {
              selectDispatchReceipt.bill_amt = dispatchList[selectedRadio].bill_amt;
              selectDispatchReceipt.received_amt = value ? parseFloat(value) : dispatchList[selectedRadio].received_amt;
            }
            //selectDispatchReceipt.balance_amt = balance_amt_temp;
            selectDispatchReceipt.balance_amt = selectDispatchReceipt.bill_amt - selectDispatchReceipt.received_amt;
            console.log("SELECTED ROW STUDY RECEIPT VALUE MOD : ", selectDispatchReceipt);

            var dispatchListNew = dispatchList.slice();
            dispatchListNew[selectedRadio].study_receipt = [];
            dispatchListNew[selectedRadio].study_receipt[0] = selectDispatchReceipt;
            setDispatchList(dispatchListNew);
           
            /*
            var selectDispatch = dispatchList[selectedRadio].study_disp[0];
            var dispatchListNew = dispatchList.slice();
            dispatchListNew[selectedRadio].study_disp[0] = selectDispatch;
            setDispatchList(dispatchListNew);
            */

            //selectDispatchReceipt.bill_amt = formValues["bill_amt_study"];
            //selectDispatchReceipt.balance_amt = formValues["balance_amt_study"];
          } 
          setFormValues({ ...formValues, [name]: e.currentTarget.value, balance_amt_study: balance_amt_temp }); 
        }
        
      }  
      else{ 
      setFormValues({ ...formValues, [name]: value });
      }
      //console.log("Clicked data : ", data);
    
    };

    const dispatchRowClick = async (e, id) => {
      console.log("Dispatch row clicked : ", e);
      console.log("Dispatch row clicked Target : ", e.target);
      console.log("Dispatch row clicked CURRENT Target: ", e.currentTarget);
      console.log("Dispatch row clicked CURRENT Target CHECKED : ", e.target.checked);

      var selectedTagId = e.target.id;
      if (selectedTagId) {
        var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
        var nameOfElement = selectedTagId.substring( 0, selectedTagId.lastIndexOf("-"));
        console.log("Name of element VIEW: ", nameOfElement);
        console.log("Current id VIEW : ", currentId);
        //console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ", patientList[currentId]);
        setCurSelRowRadio(parseInt(currentId));
      }
      console.log("Clicked at : ", id.idx);
    };

    const handleDialogRadio = async (e) => {
      console.log("handleDialogRadio called  : ", e);
      const { name, id } = e.currentTarget;
      console.log("Captured value name at handleDialogRadioChange : ", name);
      console.log("Captured value id at handleDialogRadioChange : ", id);
      var currentRowName = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element at handleDialogRadioChange : ", nameOfElement);
      console.log("Current row id at handleDialogRadioChange : ", currentRowName);
      setSelectedRadio(parseInt(currentRowName));
      //var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcId(dcList[currentRowName].indc_id, selectedRowId);
    };

    const onChangeDispatchDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, disp_date: e.target.value });
    };
  

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

 
console.log("<<<<<<<<<<<< Dispatch List >>>>>>>>>> : ", dispatchList);
  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'addUpdateStudyProgress' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'addUpdateStudyProgress')).page_title_addupd : "Dispatch"}</span>
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>

               {/* 
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                //disabled={menuSettings.permissions.find(element => (element.code === 'listItemCategories' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                */}
                
              </div>
            </Col>
          </Row>
          <hr align="center" />
          <Row>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Patient Mobile"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.mobile}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.mobile}</p>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="name"
              id="name"
              placeholder="Patient Name"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.name}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.name}</p>
          </Col>

          <Col xs={1} md={1} sm={1} lg={1}>
            <Button
              variant="warning"
              id="btn-search-id"
              name="btn-search-name"
              //type="submit"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
            <Button
              variant="secondary"
              id="btn-search-id"
              name="btn-search-name"
              onClick={handleClearSearch}
            >
              Clear Search
            </Button>
          </Col>
          </Row>
          { isSearched && (
              <>
          <div className="ui-form">
            
            <Row>
              <h4> Studies </h4>
            </Row>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{width: "3%"}}>...</th>
                  <th className="table-row-heading" style={{width: "3%"}}>S.No</th>
                  <th className="table-row-heading" style={{width: "8%"}}>Study Type</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Study ID</th>
                  <th className="table-row-heading" style={{width: "9%"}}>Study Date</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Ref.Physician</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Patient ID</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Patient Name</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Patient Mobile</th>
                  <th className="table-row-heading" style={{width: "7%"}}>Bill Amount</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Received Amount</th>
                  <th className="table-row-heading" style={{width: "8%"}}>Balance Amount</th>
                  <th className="table-row-heading" style={{width: "10%"}}>Report Status</th>
                </tr>
              </thead>
              <tbody>
                {dispatchList.map((dispatch, idx) => (
                  <tr
                    key={`dispatch-tr-key-${idx}`}
                    onClick={(e, id = { idx }) => dispatchRowClick(e, id)}
                    id={`dispatch-tr-id-${idx}`}
                    name={`dispatch-tr-name-${idx}`}
                    //charges={`dispatch-tr-charges-${idx}`}
                    //payment={`dispatch-tr-payment-${idx}`}
                  >
                    <td style={{width: "3%"}}>
                    <Form.Check
                                  //disabled={isReadOnly}
                                  inline
                                  name={`dc-radio-${idx}`}
                                  type={"radio"}
                                  id={`dc-radio-${idx}`}
                                  onChange={(e) => handleDialogRadio(e)}
                                  //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                                  checked={idx === selectedRadio ? true : false}
                    ></Form.Check>
                    </td>
                    <td key={idx} style={{width: "3%"}}>{idx + 1}</td>
                    <td style={{width: "8%"}}>{dispatch.study_type}</td>
                    <td style={{width: "7%"}}>{dispatch.study_no}</td>
                    <td style={{width: "9%"}}>{dispatch.study_date}</td>
                    <td style={{width: "10%"}}>{dispatch.physician}</td>
                    <td style={{width: "7%"}}>{dispatch.patient_code}</td>
                    <td style={{width: "10%"}}>{dispatch.patient_name}</td>
                    <td style={{width: "7%"}}>{dispatch.patient_mobile}</td>
                    <td style={{width: "7%", textAlign: "right"}}>{("study_receipt" in dispatch && dispatch.study_receipt !== null && dispatch.study_receipt.length > 0) ? dispatch.study_receipt[0].bill_amt : dispatch.bill_amt}</td>
                    <td style={{width: "10%", flexDirection: "row", textAlign: "right"}}>
                      
                      {("study_receipt" in dispatch && dispatch.study_receipt !== null && dispatch.study_receipt.length > 0) ? dispatch.study_receipt[0].received_amt : dispatch.received_amt}
                      {curSelRowRadio === idx &&
                      <>
                         &nbsp;&nbsp;
                     <BsPencilSquare
                        size={18}
                        style={{ color: "blue", cursor: "pointer"}}
                        onClick={(e) =>  handleAmountEdit(e, 'notes')}
                        name={`composite-edit-icon-${idx}`}
                        id={`composite-edit-icon-${idx}`}
                        />
                      </>
                    }
                       
                      </td>
                    <td style={{width: "8%", textAlign: "right"}}>
                    {("study_receipt" in dispatch && dispatch.study_receipt !== null && dispatch.study_receipt.length > 0) ? dispatch.study_receipt[0].balance_amt : dispatch.balance_amt}</td>
                    <td style={{width: "10%", flexDirection: "row", textAlign: "left"}}>
                      {dispatch.disp_status}
                      {curSelRowRadio === idx &&
                      <>
                       &nbsp;&nbsp;
                      <BsPencilSquare
                        size={18}
                        style={{ color: "blue", cursor: "pointer"}}
                        onClick={(e) =>  handleStatusEdit(e, 'notes')}
                        name={`composite-edit-icon-${idx}`}
                        id={`composite-edit-icon-${idx}`}
                        />
                       </>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
         { isEditable && (
          <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Receipt Details
                    </Badge>
                  </h4>
                </Col>
                <Row>
                <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Receipt No:
                      </Form.Label>
                         &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label style={{}}>
                        {formValues.receipt_no_study}
                      </Form.Label>
                      </Col>
                      
                       <Col xs={"5"} sm={"5"} md={"5"} lg={"5"} >
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Receipt Date:
                      </Form.Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Label style={{}}>
                        {formValues.receipt_date_study}
                      </Form.Label>
                       </Col>
                      
                </Row>
                
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Bill Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="bill_amt_study"
                    id="bill_amt_study"
                    placeholder="Bill Amount"
                    value={formValues.bill_amt_study}
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    disabled="true"
                  /> 
                  </Form.Group>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="received_amt_study"
                    id="received_amt_study"
                    placeholder="Received Amount"
                    value={formValues.received_amt_study}
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    //disabled="true"
                  /> 
                  </Form.Group>
                  <p className="error-msg">{formErrors.received_amt_study}</p>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label >Balance Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="balance_amt_study"
                    id="balance_amt_study"
                    placeholder="Balance Amount"
                    value={formValues.balance_amt_study}
                    onChange={(e) => handleValueChange(e)}
                    //readOnly={isReadOnly}
                    disabled="true"
                  /> 
                  </Form.Group>
                </Col>

                <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Note</Form.Label>
                  <Form.Control
                    type="text"
                    name="notes_study"
                    placeholder="Note"
                    value={formValues.notes_study}
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                  />
             </Form.Group>
                </Col>
                { dispatchList[selectedRadio].hasOwnProperty("study_receipt") && dispatchList[selectedRadio].study_receipt !== null && dispatchList[selectedRadio].study_receipt.length > 0 && 
                <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: "30px"}}>
                <Button id="receiptPrint" name="receiptPrint" onClick={(e) => handlePrintReceipt(e)}>
                    <BsFillPrinterFill
                      size={18}
                      style={{ color: "white", cursor: "pointer" }}
                      name={`print-branch-icon`}
                      id={`print-branch-icon`}
                    />
                  </Button>
                  </Col>
                }
              </Row>
               )}

            { statusState && ( 
          <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Dispatch Details
                    </Badge>
                  </h4>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field">Dispatch Date</Form.Label>

                    <Form.Control
                      type="date"
                      name="disp_date"
                      value={formValues.disp_date}
                      onChange={onChangeDispatchDatePicker}
                      //readOnly={isReadOnly}
                    />
                  </Form.Group>
                  <p className="error-msg">{formErrors.disp_date}</p>
                </Col>
              
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received By</Form.Label>
                  <Form.Control
                    type="text"
                    name="receivedby_disp"
                    id="receivedby_disp"
                    placeholder="Received by"
                    onChange={(e) => handleValueChange(e)}
                    value={formValues.receivedby_disp}
                    readOnly={isReadOnly}
                  /> 
                  </Form.Group>
                  <p className="error-msg">{formErrors.receivedby_disp}</p>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobileno_disp"
                    placeholder="Mobile"
                    maxLength={10}
                    onChange={(e) => handleValueChange(e)}
                    value={formValues.mobileno_disp}
                    readOnly={isReadOnly}
                  />
                  </Form.Group>
                  <p className="error-msg">{formErrors.mobileno_disp}</p>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    type="text"
                    name="notes_disp"
                    placeholder="Notes"
                    onChange={(e) => handleValueChange(e)}
                    value={formValues.notes_disp}
                    readOnly={isReadOnly}
                  />
             </Form.Group>
                </Col>
              </Row>
             )}
          <div className="ui-form">
            
          {(statusState || isEditable) && (
              <Row>          
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                    
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
          )}
             
            
           
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
              {(alertPurpose === 'confirmation-amount' || alertPurpose === 'confirmation-status') &&
                  <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
                }
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          </>
            )}
       
        </Form>
        )
           :
           <div className="container-spinner">
           <div className="vertical-center-spinner">
            <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
            
                   <span className="visually-hidden">Loading...</span>
                 </Spinner>
            </div>
            </div>
         }
       
      </Container>
  );
};

export default AddUpdateStudyProgress;
