import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import { Table } from "react-bootstrap";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image,
  Pagination,
  Text

} from "react-bootstrap";
//import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain.js";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";
import "../Privacy.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config.js";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';


function PrivacyPolicy() {
  const initialized = useRef(false);
  var navigate = useNavigate();
  
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

 
  return (
    <Container>
      <SidebarMain />
      <Row>
              <Col xs={9} md={9} sm={9} lg={9}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Privacy Policy</span>
           
             </Col>
             </Row>
             <hr align = "center"/>
             
   <div class="permission-section">
<ul class="terms center-align">
<li><h1 style={{fontSize: "25px"}}><b>Vilvam Books Terms and Conditions and Privacy Policy</b></h1></li>
<li>The terms used in the Privacy Policy (“Policy”) have the meaning as defined in the Terms of Use and is to be read together with the Terms of Use. The present policy lays down how the Company collects, uses, discloses, shares and transfers Information of Users. This Policy would apply to anybody who visits Vilvam Books Platform or opens an account with the Company on the Vilvam Books Platform, as the case may be. </li>
<li> Simply Vilvam Books Apps Private Limited (“the Company”) having its registered address FLAT NO 223 WINGS 2 DSR EDEN GREEN APARTMENTS, Bangalore, Karnataka - 560035 recognizes the need for appropriate protection and management of any information shared with the Company or on Vilvam Books Platform.</li>
<li> By using or accessing the Vilvam Books platform in any manner or opening an Account, it is acknowledged that the present Policy is accepted and it further consents that the Company will collect, use, disclose, share and transfer information and personal data including but not limited to sensitive Personal Information. This Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder. </li>
<li> However, in case the User does not intend to share some of the Information as referred herein below for use by the Company as stated, the User is free to opt-out or not use the Vilvam Books platform and/or the Products and Services by deleting the Account and in such a case, are not required to give consent as per the present Privacy Policy </li>
<li> In the case in future it is felt that the Information or a part thereof as collected should not be used or should be deleted, consent granted in the present Policy can be withdrawn, by writing to the Company seeking to withdraw the consent so granted or deleting the Account, as the case may be. However, in case the consent for the Company to use the Information as provided in the present Policy is withdrawn, the Products and Services cannot be offered to the User by the Company. </li>
</ul>
<ul class="terms center-align">
<li>Modification of privacy policy</li>
<li>These policies may be updated from time to time in the future. The User should agree to review our privacy policies regularly by visiting this page. Continued access to or use of the service will mean that the User agrees to the change.
</li>
</ul>
<ul class="terms center-align">
<li>When this privacy statement applies</li>
<li>
Its liability to the User is limited to those who have downloaded the Vilvam Books application and logged in by clicking on the “I Agree/Login” button for Terms and Conditions or has explicitly consented to the policy on any other product/service page offered to the User
</li>
</ul>
<ul class="terms center-align">
<li>How is the information collected, used, disclosed, shared and stored</li>
<li>
1. The Company collects, uses, discloses, shares and transfers Information, including but not limited to User content, other content, communications, information etc. provided by Users when the Users access or use the Vilvam Books platform for the Products and Services. By use of the Vilvam Books platform and/or Products and Services, express consent and permission is granted to the Company for having access to, using, processing, disclosing, sharing and transferring of information in accordance with this Policy and the Terms of Use.
</li>
<li>
2. For the purpose of these Terms, the term “Personal Information” shall mean any information provided by the User and shall include, without limitation User content, any and all documents, details, information etc. shared with the Company by the User and the One Time Password (OTP) received by the User from the government authorities/good and service tax authority for authenticating/verifying the goods and service tax registration process; in furtherance to the Terms of Use.
</li>
<li>
For the purpose of these Terms, the term “Non-Personal Information” shall mean information that does not specifically identify an individual but includes information from such as the type of internet browser used, mobile device used, computer or mobile device’s unique device ID, Internet Service Provider (ISP) and Internet Protocol (IP) address. The Company may also collect Non-Personal Information that is provided.
</li>
<li>
Personal and Non-Personal Information together is referred to as “Information”. The Company may collect Information when registration or opening of an Account happens on the Vilvam Books platform, when the features or the Products and Services are used, when the User shares the User content with the Company and when the User interacts with the customer care team including the details of the message or chats etc.
</li>
<li>
3. The Company may use cookies to monitor usage including, without limitation, to provide useful features to simplify experiences for the User on the Vilvam Books platform, like remembering login ID and certain Personal Information and to deliver relevant content based on preferences, usage patterns and location.
</li>
<li>
4. There could also arise a requirement of providing credit/debit card when the User is required to payment Government Fees and/or Facilitation Fees as per the Terms of Use. The Company or any third party providing services related to payment may use this information, such as account and authentication information and billing, delivery and contact details. The Company shall in no case be liable for any loss or damage sustained by the User arising out of any disclosure (inadvertent or otherwise) of any information relating to bank account, credit or debit card details in the course of any online transactions or payments made relating to Government Fees and/or Facilitation Fees.
</li>
<li>
5. The Company collects information about the User’s use of the Vilvam Books platform and/or Products and Service, such as the features used, any activity carried out, the actions that are taken, the time, frequency and duration of the said activities.
</li>
<li>
6. The Company is required to collect information from and about the computers, tablets, phones and other web-connected devices used and the Company then combines this information across different devices that are used.
</li>
<li>
Information that the Company obtains from these devices includes:
</li>
<li>
a. Information including but not limited to the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins, name of mobile operator or ISP, language, time zone, mobile phone number, IP address, connection speed and, in some cases, information about other devices that are nearby or on user network etc.
</li>
<li>
b. Information including but not limited to access to beacons and mobile phone masts, access to GPS location, network, camera or photos, data from cookies stored on user devices, storage space or disk on the device etc.
</li>
<li>
c. Location-related information – such as current location to provide, personalized and improved use of the Vilvam Books platform and/or Products and Services. Location-related information can be based on things such as precise device location, IP addresses etc.
</li>
</ul>
<ul class="terms center-align">
<li>Collection of financial sms information</li>
<li>
We don’t collect, read or store your personal SMS from your inbox. We collect and monitor only financial SMS sent by 6-digit alphanumeric senders from your inbox which helps us in identifying the various bank accounts that you may be holding, cash flow patterns, description and amount of the transactions undertaken by you as a user to help us perform a credit risk assessment which enables us to determine your risk profile and to provide you with the appropriate credit analysis. This process will enable you to take financial facilities from the regulated financial entities available on the Platform. This Financial SMS data also includes your historical data.
</li>
</ul>
<ul class="terms center-align">
<li>Collection of device location and device information </li>
<li>
We collect and monitor the information about the location of your device to provide serviceability of your loan application, to reduce the risk associated with your loan application and to provide pre-approved customised loan offers. This also helps us to verify the address, make a better credit risk decision and expedite know your customer (KYC) process. Information the App collects, and its usage depends on how you manage your privacy controls on your device. When you install the App, we store the information we collect with unique identifiers tied to the device you are using. We collect information from the device when you download and install the App and explicitly seek permissions from You to get the required information from the device. The information we collect from your device includes the hardware model, build model, RAM, storage; unique device identifiers like IMEI, serial number, SSAID; SIM information that includes network operator, roaming state, MNC and MCC codes, WIFI information that includes MAC address and mobile network information to uniquely identify the devices and ensure that no unauthorized device acts on your behalf to prevent frauds
</li>
</ul>
<ul class="terms center-align">
<li>Collection of installed applications </li>
<li>
We collect a list of the installed applications’ metadata information which includes the application name, package name, installed time, updated time, version name and version code of each installed application on your device to assess your credit worthiness and enrich your profile with pre-approved customized loan offers.
</li>
</ul>
<ul class="terms center-align">
<li>Storage</li>
<li>
We require storage permission so that your KYC and other relevant documents can be securely downloaded and saved on your phone. You can then easily upload the correct KYC related documents for faster loan application details filling and disbursal process. This ensures that you are provided with a seamless experience while using the application.
</li>
</ul>
<ul class="terms center-align">
<li>Camera</li>
<li>
We require the camera information permission to provide you with an easy/smooth experience and to enable you to click photos of your KYC documents along with other requisite documents and upload the same on the App during your loan application journey.
</li>
</ul>
<ul class="terms center-align">
<li>Collection of other non-personal information</li>
<li>
We automatically track certain information about you based upon your behaviour on our Platform. We use this information to do internal research on our users’ demographics, interests, and behaviour to better understand, protect and serve our users and improve our services. This information is compiled and analysed on an aggregated basis. We also collect your Internet Protocol (IP) address and the URL used by you to connect your computer to the internet, etc. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address. Cookies are small data files that a Website stores on Your computer. We will use cookies on our Website similar to other lending websites/apps and online marketplace websites/apps. Use of this information helps Us identify You in order to make our Website more user friendly. Most browsers will permit You to decline cookies but if You choose to do this it might affect service on some parts of Our Website. If you choose to make a purchase through the Platform, we collect information about your buying behaviour. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law. if you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we collect such information into a file specific to you.
</li>
</ul>
<ul class="terms center-align">
<li>Link to third-party sdk</li>
<li>
The Vilvam Books Platform has a link to a registered third party SDK that collects data on our behalf and data is stored on a secured server to perform a credit risk assessment. We ensure that our third-party service provider takes extensive security measures in order to protect your personal information against loss, misuse or alteration of the data. Our third-party service provider employs separation of environments and segregation of duties and has strict role-based access control on a documented, authorized, need to-use basis. The stored data is protected and stored by application-level encryption. They enforce key management services to limit access to data. Furthermore, our registered third party service provider provides hosting security – they use industry leading anti-virus, anti-malware, intrusion prevention systems, intrusion detection systems, file integrity monitoring, and application control solutions
</li>
</ul>
<ul class="terms center-align">
<li>How Vilvam Books uses user data </li>
<li>
The Company may use the Information for:
</li>
<li>
1. Providing access to the Vilvam Books platform and/or use of the Products and Services.
</li>
<li>
2. Manage Account with the Company.
</li>
<li>
3. Authentication by using One Time Password received from the for the Purpose including but not limited to government authorities/good and service tax authority for authenticating/verifying the goods and service tax registration process, Bureau check or any other product/service availed by the user from time to time on Vilvam Books.
</li>
<li>
4. Send SMS for authenticating transactions via One Time Password (OTP) etc.
</li>
<li>
5. Conduct general survey or analysis of Accounts or otherwise.
</li>
<li>
6. Develop, test and improve the Vilvam Books platform and/or Products and Services, testing and troubleshooting new products and features, removing any glitches including technical problems, improving the experience of navigating and carrying out transactions and improving the services and content, Products and Service on the Vilvam Books platform.
</li>
<li>
7. Based on Information, share sponsored content and show ads, including relating to third parties, that the Company thinks will be relevant.
</li>
</ul>
<ul class="terms center-align">
<li>
Sharing of personal information
</li>
<li>
The Company may disclose or share Personal Information including but not limited to User Content in the following ways:
</li>
<li>
1. Disclosure or sharing with government authorities including but not limited to the Goods and Service Tax Authority, MSME Authority, FSSAI Authority.
Information including Personal Information may be shared with and disclosed to government authorities including but not limited to the Goods and Service Tax Authority, MSME Authority, FSSAI Authority, in order to enable providing the Products and Services.
</li>
<li>
2. Disclosure to Government authorities or as required by law information may be shared with Government and/or statutory authorities or under court orders or other legal processes; to establish or exercise legal rights; to defend against legal claims; or as otherwise required by law. The Company warrants that such information will be disclosed only in accordance with applicable directions, instructions, laws and regulations. </li>
<li>
3. Disclosure to third parties the Company may provide, disclose and share information including Personal Information that is collected to third-party service providers, database service providers, backup and disaster recovery service providers, email service providers and also make it accessible to technology partners, subsidiaries and affiliates, including outside India, to help ensure availability of Vilvam Books platform and providing the Products and Services
</li>
<li>
4. Disclosure to prospective Acquirers The Company may disclose information including Personal Information to an acquirer, assignee or other successor entity in connection with a sale, merger, or reorganization of all or substantially all of the equity or business of the Company.
</li>
<li>
5. Disclosure to Facilitate Payment on Payment Gateways In order to process payment transactions, the Company may direct to a payment gateway service provider of its own choice, which may have access to the Personal Information provided while making such payment.
</li>
</ul>
<ul class="terms center-align">
<li>Protection of information</li>
<li>
The Company, on a best effort basis, shall attempt to take adequate measures to protect the security of Personal Information and to ensure that the choices for its intended use are honoured. The Company also takes adequate precautions, on a best effort basis, to protect data from loss, misuse, unauthorized access or disclosure, alteration or destruction.
</li>
<li>
The Company uses industry standards of physical, technical and administrative security measures to keep Information, on best effort basis, secure although is unequivocally understood and agreed that the same cannot be foolproof since no security system is impassable and the Company does not guarantee that the Information may be absolutely free from invasion.
</li>
<li>
Please note that e-mails, messaging and means of communication with the Company may not be encrypted, and therefore the Company advises not to communicate any confidential information through these means.
</li>
<li>
The Company is not responsible for any breach of security or for any actions or inactions of any Users or other third parties including but not limited to government authorities that receive Information.
</li>
<li>
The User is solely responsible for maintaining the confidentiality of its username and password. To ensure the safety of Personal Information, it is advised that the username and password should not be shared with anyone. The Company would not be liable or responsible for any breach of this covenant for any reason.
</li>
<li>
The Company would retain Personal Information only as long as the entity or User to whom the said Personal Information belongs has an active Account or as permitted/required by applicable law. Generally, Personal Information, upon deletion of a related Account, is also deleted unless the same is required to comply with any legal requirements, fraud prevention or assistance in any enquiry, for resolving disputes, to protect interests of the Company, to continue to develop and improve the Products and Services
</li>
</ul>
<ul class="terms center-align">
<li>Grievance redressal mechanism</li>
<li>
In accordance with the Information Technology Act, 2000 and Rules made thereunder, the contact details for raising grievances or obtaining any further information or enquiry, if any are provided below:
</li>
<li>
<span class="for">Address: </span> 2/14, Dhanam Nagar, Mylampatti Post,Chinniyampalayam, Coimbatore - 641062.</li>
<li> <span class="for"> E-mail: </span>support@avinasoft.com</li>
<li> <span class="for">Contact number: </span>9942410747</li>
</ul>
</div>  
 
    </Container>
   
  );
}
export default PrivacyPolicy;
