import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import  { Table } from "react-bootstrap";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image,
  Pagination
} from "react-bootstrap";
//import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain.js";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config.js";
import spinner_logo from '../assets/images/spinner_logo.png';


function ListItemCategories() {
  const initialized = useRef(false);
  var navigate = useNavigate();
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

/*
  let patientArr = [
    { 
     reg_no: "AB001",
     name: "Varun",
     age: "26",
     mobile: "9081234567",
     ref_no: "R123",
     status: "Active"
    },
    { 
      reg_no: "AB002",
      name: "Arjun",
      age: "28",
      mobile: "894312678",
      ref_no: "R124",
      status: "Active"
     },
     { 
      reg_no: "AB003",
      name: "Harin",
      age: "25",
      mobile: "9081234567",
      ref_no: "R125",
      status: "Active"
     },
  ];
  */

  let intialValues = {
    code: "",
    name: "",
    mobile_primary: "",
    ref_no: "",
  };

  const [categoryList, setCategoryList] = useState([]);
  const [categoryListFull, setCategoryListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState( "Invalid authentication Session!");
  const [selectedId, setSelectedId] = useState(-1);
  const [showDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  var validateErr = false;

 
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

 
  
  const getItemCategoryList = async () => {
    //var retStateLoad = false;
    //var patientRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getItemCategoryList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setCategoryList(res.data.detail.data);
        setCategoryListFull(res.data.detail.data);
        setToRender(true);

        //patientRet = res.data.detail.data;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    //return [retStateLoad, patientRet];
  };
  

  
 

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::ListPatients called ##########################");
     getItemCategoryList();
      /*
      (async () => {
        if (retStateLoad) {
          console.log("<<<<<<<<<<<<<<<<<< CURRENT BRANCH )))))))))))))))))) : ", currBranchRef.current);
          var [retStateLoad, patientRet] = await getPatientList();
          setToRender(true);
        }
       
      })();
      */

    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add patients called : ", e);
    navigate("/addUpdateItemCategory", {state: { passed_element: [], action: "add" },
  });
  };

  const handleView = async (e) => {
    console.log("handle edit category called : ", e);
    console.log("handle edit category currentTarget called : ", e.currentTarget);
    console.log("handle edit category target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
      var nameOfElement = selectedTagId.substring( 0, selectedTagId.lastIndexOf("-"));
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);
      console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",categoryList[currentId]);
      navigate("/addUpdateItemCategory", {
        state: {
          passed_element: categoryList[currentId],
          action: "view",
        },
      });
    }
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

  //var filename_gen = "customer";
 
  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent} />
      { toRender ?  
      <form>
          <Row>
              <Col xs={9} md={9} sm={9} lg={9}> 
                <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listItemCategories' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listItemCategories')).page_title_list : "Study Categories"}</span>
             </Col>

            <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
            {/*{menuSettings.permissions.find(element => (element.code === 'listStudyItemCategories' && element.a_add === true)) &&*/}
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className="justify-content-end float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Study Category
              </Button>
               
            </Col>
          </Row>
          <hr align="center" />
          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                
                <th className="table-row-heading" style={{textAlign: "center"}}>Study Type</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Description</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Status</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
              </tr>
            </thead>
            <tbody>
              {categoryList.map((category, idx) => (
                <tr>
                  <td style={{textAlign: "right"}}>{idx + 1}</td>
                  <td style={{textAlign: "left"}}>{category.type}</td>
                  <td style={{textAlign: "left"}}>{category.name}</td>
                  <td style={{textAlign: "left"}}>{category.description}</td>
                  <td style={{textAlign: "left"}}>{category.status}</td>

                  <td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`view-btn-patient-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`view-icon-category-name-${idx}`}
                      id={`view-icon-category-id-${idx}`}
                      //disabled={menuSettings.permissions.find(element => (element.code === 'listStudyItemCategories' && element.a_detailview === true)) ? false : true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       </div>
       </div>
      } 
      
     </Container>
   
  );
}
export default ListItemCategories;
