import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client } from "../config/Config.js";
import axios from "axios";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain.js";
import spinner_logo from '../assets/images/spinner_logo.png';

//function AddUpdateCustomer () {
const AddUpdateDocSetting = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  let initialItems = [];
  const intialValues = {
    doc_type_name: "",
    doc_no_prefix: "",
    doc_no_suffix: "",
    doc_no_start: "",
    doc_no_generated:"",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [checkState, setCheckState] =  useState(false);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  var validateErr = false;

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    if (name === "doc_no_start" || name === "doc_no_generated" ) {
      console.log("Inside check ******************");
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
      if ( name === 'doc_no_start') {
        formValues["doc_no_generated"] = valueTmp;
      }
      setFormValues({ ...formValues, [name]: valueTmp });

      
      //}
    } else {

   setFormValues({...formValues, [name]: value});
    }
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    {/*
    if ( values.phone ) {
    console.log("phone number length : ", values["phone"].length);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values["pincode"].length);
    }
  */}

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = " Document Name is required!";
      validateErr = true;
      //setValidateError(true);
    }
    
    if (!values.prefix) {
      errors.prefix = "Prefix is required!";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.sno_start) {
      errors.sno_start = "Start Number is required!";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.sno_lastgen) {
      errors.sno_lastgen = "Last Generated Number is required!";
      validateErr = true;
      //setValidateError(true);
    }
    */
   
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
 
 
  const handleEdit = async (e) => {
    console.log("handle edit clicked : ", e);

  /*
  if ( !formValues["is_editable"] ) {
    setShowMsg("Generated document sequence number may affect. Unable to edit. please contact administrator");
    setShowMsgTitle("Warning");
    setShowDialog(true);
  }
  else {
    setIsReadOnly(false);
  }
  */
  setIsReadOnly(false);
  };

  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListDocNumber");
  };

  const callDocDetById = async(docIdPassed) => {

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //var apiToCall = "";

    var reqParams = {
      id: docIdPassed,
    };

    var res = await axios.post(client.domain + "/bms/getDocNumberDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);
        setFormValues(res.data.detail.data[0]);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
      
  };


  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateDocNumber called ##########################"
      );

      if (state === null) {
        navigate("/listDocNumber");
      } else {
        console.log("PASSED STATE VALUE IN DocNumber Add/Update page : ", state);

        
         if ( state.action === 'view' || state.action === 'edit') {
          if ( state.passed_element.allow_manual === 'Yes') {
            setCheckState(true);
          }
          // call api to get business info
          callDocDetById(state.passed_element.id);
        }
        else {
          setFormValues(state.passed_element);
        }
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      setToRender(true);
      initialized.current = true;
    }
  }, []);
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      
      var apiToCall = "/bms/updateDocNumber";
     
      var reqParam = {
        id: formValues["id"],
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        prefix: formValues["prefix"],
        suffix: formValues["suffix"] || "",
        sno_start: formValues["sno_start"],
        allow_manual: checkState,
        
       
      };
      

     
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        setShowMsg("Serial Number Updated Successfully !");
       
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };

  /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: ` ${aIVal}`}
      var apiToCall = "/bms/updateDocNumber";
     
      var reqParam = {
        id: formValues["id"],
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        prefix: formValues["prefix"],
        sno_start: formValues["sno_start"],
        allow_manual:checkState,
        
       
      };
     
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
         
        }
      }
      setSubmitAction(false);
    }
  };
  */
  const handlePostSaveOk = () => {
    console.log("user confirmed to DocNumber added ...");
    
    setShowDialog(false);
    navigate("/listDocSettings");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
  };

  console.log("formvalues FULL : ", formValues);

  const handleClose = async (e) => {
    setShow(false);
    navigate(-1);
  };

  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    setCheckState(e.target.checked);
    //setCheckState(e.target.checked);
    //setRefresh(true);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

  

  return (
    <>
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listDocSettings' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listDocSettings')).page_title_addupd : "Branches"}</span>
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md"
                key={`back-btn-patient-key`}
               
                style={{ cursor: "pointer" }}
                onClick={(e) => handleBack(e)}
                name={`back-icon-patient-name`}
                id={`edit-icon-patient-id`}>
                  <BsArrowLeftCircle
                    size={24}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"
                key={`edit-btn-key`}
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEdit(e)}
                name={`edit-icon-paBiSolidEditAlttient-name`}
                id={`edit-icon-id`}
                //disabled={(menuSettings.permissions.find(element => (element.code === 'listDocSettings' && element.a_edit === true)) ? false : true) && (formValues["allow_manual"] === true ? false : true)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listDocSettings' && element.a_edit === true)) &&formValues["allow_manual"] === true ? false : true}
                >
              
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
            {/*
            <Row style={{ height: props.height }}>
              <hr />
            </Row>
              */}

            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">  Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Prefix</Form.Label>
                  <Form.Control
                    type="text"
                    id="prefix-id"
                    name="prefix"
                    placeholder="Prefix"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["prefix"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.prefix}</p>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Suffix</Form.Label>
                  <Form.Control
                    type="text"
                    id="suffix-id"
                    name="suffix"
                    placeholder="Suffix"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["suffix"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.suffix}</p>
              </Col>
              </Row>
              <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> Start Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="startnumber-id"
                    name="sno_start"
                    placeholder="Start Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["sno_start"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.sno_start}</p>
              </Col>
              {/* 
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> Last Generated Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="generatednumber-id"
                    name="sno_lastgen"
                    placeholder="Last Generated Number"
                    onChange={(e) => handleOnChange(e)}
                    disabled={true}
                    value={formValues["sno_lastgen"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.sno_lastgen}</p>
              </Col> 
                  */}
              </Row>
              <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                       <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Allow Manual Numbering"
                            name="allow_manual"
                            id="id-allow"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                          </Col>
              </Row>
             
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                       {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                       aria-hidden="true"
                       />
                      <span className="visually-hidden">Loading...</span>
                      </>  
                      }
                      Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
        <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}> 
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateDocSetting;
