import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import { Table } from "react-bootstrap";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain.js";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config.js";
  import spinner_logo from '../assets/images/spinner_logo.png';
  import {formatDateIndian} from "./utils.js";
  
  
  function ViewcsbyPhysician() {
    const initialized = useRef(false);
    var navigate = useNavigate();
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);

    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);

    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
    function padTo2Digits(num) {
      //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
      var modNum = num.toString().padStart(2, "0");
      console.log("Modified  value : ", modNum);
      return modNum;
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }
  
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
    const intialValues = {
      //date_from: formatDate(new Date(firstDay)),
      date_from: formatDate(new Date(firstDay)),
      date_to: formatDate(new Date()),
      study_no:  "",
    }

    let customerArr = [
  
      { value: 1, label: "All Customers" },
      { value: 2, label: "Deppam Motors" },
      { value: 3, label: "ABC Pumps" },
      { value: 4, label: "ADP Compressors" },
    ];
  
    let studyArr = [
      {
      study_type: "Scan",
      study: "test",
      no_studies: "3",
      total_amt: "20000",
      },

      { 
        physician: "Farina",
        study_type: "Lab",
        study: "test",
        no_studies: "2",
        total_amt: "20000",
        },

        { 
          study_type: "Scan",
          study: "test",
          no_studies: "3",
          total_amt: "10000",
          },
      
    ];
  
   
    
    
  
    const [caseList, setCaseList] = useState([]); 
    const [caseSummary, setCaseSummary] = useState([]);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");
    const [formErrors, setFormErrors] = useState({});
    var validateErr = false;

    const getCaseList = async () => {
      var retStateLoad = false;
      var caseRet = [];
      var summaryRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
  
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current,
          date_from: formValues["date_from"],
          date_to: formValues["date_to"],
        };
  
        var res = await axios.post(client.domain + "/bms/getCaseStudybyPhysicianList", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", res.data.detail.data.status);
          setCaseList(res.data.detail.data.casestudy);
          setCaseSummary(res.data.detail.data.casestudy_summary[0]);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          caseRet = res.data.detail.data.tax_casestudy;
          summaryRet = res.data.detail.data.casestudy_summary;
          retStateLoad = true;
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, caseRet, summaryRet];
    };
    
   /*
    const CallReportData = async () => {
      var retStateLoad = false;
      var patientRet = [];
      var physicianRet = [];
      var typeRet = [];
  
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
  
        var reqParams = {
          bid: currSelBusinessRef.current
        };
  
        var res = await axios.post(client.domain + "/bms/getReportPreLoadData", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
         
          setOptPatient(res.data.detail.data.patients);
          setOptPhysician(res.data.detail.data.physicians);
          setOptType(res.data.detail.data.studytypes);
          //setCustomerIdSelect(customerList[0]);
          //setCustomerIdSelect(res.data.detail.data.customers[0]);
         
          setToRender(true);
          patientRet = res.data.detail.data.patients;
          physicianRet = res.data.detail.data.physicians; 
          typeRet = res.data.detail.data.studytypes;
          retStateLoad = true;
  
  
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log("Request failed due to ", error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, patientRet, physicianRet,typeRet];
    };
    */
   
   
    useEffect(() => {
      if (!initialized.current) {
        console.log("*************** useEffect::ViewPhysicianSummary called ##########################");
        ( async() => {
          var [retStateLoad, caseRet, summaryRet] = await getCaseList();
        })();
        setToRender(true);
        initialized.current = true;
      }
      
    }, []);
   /*
    const handleAdd = async (e) => {
      console.log("handle add customers called : ", e);
      navigate("/addUpdateStockIn", {
        state: { passed_element: [], action: "add" },
      });
    };
    */
  
  
    const onChangeStartDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_from: e.target.value });
    };
  
    const onChangeEndDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_to: e.target.value });
    };
  

    const validate = (values) => {
      console.log("validateErr before start processing : ", validateErr);
      console.log("Validate called ...", values);
      const errors = {};
      const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
     
      console.log("VALIDATE ERROR inside validate func : ", validateErr);
      return errors;
    };
  
    const handleSearch = async (e) => {
      e.preventDefault();
      console.log("handle search called : ", e);
      await getCaseList();
    };
  
    const handleClearSearch = (e) => {
      console.log("handle  clear search called : ", e);
      //setPatientList(patientsListOrig);
      //setFormValues(intialValues);
      //setPatientList(patientListFull);
    };
  
    const handleValueChange = (e) => {
      //e.preventDefault();
  
      console.log("Clicked value : ", e);
      const { name, value } = e.target;
      console.log("Captured input element name : ", name);
      console.log("Captured input element value : ", value);
      setFormValues({ ...formValues, [name]: value });
  
      //console.log("Clicked data : ", data);
    };

    
    const sendDataToParent  = (passedBackValue) => {
      console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
      if ( passedBackValue.hasOwnProperty("business_id") ) {
        currSelBusinessRef.current = passedBackValue["business_id"];
        //navigate(0);
      }
      else if (passedBackValue.hasOwnProperty("branch_id")) {
        currBranchRef.current = passedBackValue["branch_id"];
      }
      else {
        // Future implementation
      }
      //setCurrSelBusinessId(passedBackValue);
      
      //getBranchList();
    };
  
   
    return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
        <Row>
            <Col xs={10} md={10} sm={10} lg={10}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>{menuSettings.permissions.find(element => (element.code === 'viewcsbyPhysician' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'viewcsbyPhysician')).page_title : "Case Studies by Physician"}</span>
            </Col>

           {/* 
            <Col
              xs={2}
              md={2}
              sm={2}
              lg={2}
              className="d-flex justify-content-end float-end">
              <Button id="taxPrint" name = "taxPrint"  onClick={(e)  => handleTaxPrint(e)}
              //disabled={menuSettings.permissions.find(element => (element.code === 'viewStudies' && element.a_print === true && studyList.length > 0)) ? false : true}
              >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-tax-icon`}
                    id={`print-tax-icon`}
                    
                  />
                </Button>
               
                
            </Col>
           */}
            </Row>
            <hr align="center" />
             <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Control
                  size="xs"
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                </Col>
                {/*
                <Col>
                  <Form.Label style={{ textAlign: "center", paddingTop: "15px" }}>To</Form.Label>
                </Col>
              */}
        
          
            <Col xs={2} md={2} sm={2} lg={2}>
            
                <Form.Control
                  size="xs"
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                </Col>
                <Col xs={1} md={1} sm={1} lg={1}>
                <Button
                    variant="warning"
                    id="btn-search-id"
                    name="btn-search-name"
                    type="submit"
                    onClick={handleSearch}
                  >
                      Search
                  </Button>
                  </Col>
                  </Row>
               
          <hr align="center" />
          <Row>
            <Col xs={12} md={12} sm={12} lg={12} style={{ textAlign: "right" }}>
                <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                    Total Amount :
                </Form.Label>
                <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                  {caseSummary.total_amt > 0.01 ?
                    <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(caseSummary.total_amt).toFixed(2))))}
                    </>
                  : (0.00).toFixed(2)} 
                  </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                 Total Discount Amount :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                  {caseSummary.discount_amt > 0.01 ?
                    <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(caseSummary.discount_amt).toFixed(2))))}
                    </>
                  : (0.00).toFixed(2)} 
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                  Total Net Amount :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
              {caseSummary.net_amt > 0.01 ?
                  <>
                  {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(caseSummary.net_amt).toFixed(2))))}
                  </>
                  : (0.00).toFixed(2)}
    
             </Form.Label>
            <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
            <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Received Amount :
            </Form.Label>
            <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
              {caseSummary.received_amt > 0.01 ?
              <>
              {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(caseSummary.received_amt).toFixed(2))))}
              </>
              : (0.00).toFixed(2)}
            </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

            <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                  Total Balance Amount :
            </Form.Label>
            <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
              {caseSummary.balance_amt > 0.01 ?
              <>
              {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(caseSummary.balance_amt).toFixed(2))))}
              </>
              : (0.00).toFixed(2)}
            </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
            </Col>
            </Row>
          
            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center", width: "4%"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "20%"}}>Physician</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Study Type</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Total Studies</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Total Amount</th> 
                  <th className="table-row-heading" style={{textAlign: "center", width: "8%"}}>Discount</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "8%"}}>Billed</th> 
                  <th className="table-row-heading" style={{textAlign: "center", width: "8%"}}>Received</th>  
                  <th className="table-row-heading" style={{textAlign: "center", width: "8%"}}>Balance</th> 
                </tr>
              </thead>
              <tbody>
                {caseList.map((study, idx) => (
                  <tr>
                    <td style={{textAlign: "right", width: "4%"}}>{idx + 1}</td>
                    <td style={{textAlign: "left", width: "24%"}}>{study.physician}</td>
                    <td style={{textAlign: "left", width: "8%"}}>{study.study_type}</td>
                    <td style={{textAlign: "right", width: "8%"}}>{study.cs_count}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(study.total_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "8%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(study.discount_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "8%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(study.net_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "8%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(study.received_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "8%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(study.balance_amt).toFixed(2)))}</td>
                  </tr>
                  
                ))}
               
              </tbody>
            </Table>
          
          </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }      
       </Container>
     
    );
  }
  export default ViewcsbyPhysician;