import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import { Table } from "react-bootstrap";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image,
  Pagination
} from "react-bootstrap";
//import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain.js";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config.js";
import spinner_logo from '../assets/images/spinner_logo.png';
import { getCustomerMessages } from "./utils.js";


function MyProfile() {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  let initialItems = [];
  const intialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
 // const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  

  var validateErr = false;

  

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    /*
    if (name === "phone" || name === "contact_person_number" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "opening_balance") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    */
    setFormValues({ ...formValues, [name]: value })
   
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    if ( values.phone ) {
    console.log("phone number length : ", values["phone"].length);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values["pincode"].length);
    }

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.old_password) {
      errors.old_password = getCustomerMessages('ui_messages', "MSG_OLD_PASSWORD_VALIDATE");
      validateErr = true;
      //setValidateError(true);
    }

    if (!values.new_password) {
      errors.new_password = getCustomerMessages('ui_messages', "MSG_NEW_PASSWORD_VALIDATE");
      validateErr = true;
      //setValidateError(true);
    }

    if (!values.confirm_password) {
      errors.confirm_password = getCustomerMessages('ui_messages', "MSG_CONFIRM_PASSWORD_VALIDATE");
      validateErr = true;
      //setValidateError(true);
    }
   
    
    /*
    if (!values.phone) {
      errors.phone = "Phone number is required!";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.contact_person_number) {
      errors.contact_person_number = "Contact Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    

    

    if (values.contact_person_number && values.contact_person_number.length != 10) {
      errors.contact_person_number = "Contact Number should be 10 digits!";
      validateErr = true;
      setValidateError(true);
    }
  
 
    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      //callUpdateApi();
     
    }
  };
    

  
  
  
 
  

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleCloseDialog = () => {
    setShowDialog(false);
    //navigate("/ListCustomers");
  };
  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::MyProfile called ##########################");
      setToRender(true);
      
      
      initialized.current = true;
    }
  }, []);

 /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/crm/addCustomer";
      } else if (state.action === "view") {
        apiToCall = "/crm/updateCustomer";
      } else {
        apiToCall = "/crm/addCustomer";
      }

      var reqParam = {
        id: formValues["id"],
        name: formValues["name"] || "",
        display_name: formValues["name"] || "",
        contact_person: formValues["contact_person"],
        contact_person_number: formValues["contact_person_number"],
        gst_no: formValues["gst_no"] || "",
        phone: formValues["phone"] || "",
        contact_number_primary: formValues["contact_number_primary"] || "",
        Contact_number_secondary: formValues["contact_number_secondary"] || "",
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"] || "",
        address_line2: formValues["address_line2"] || "",
        district_id: districtIdSelect.value,
        pincode: formValues["pincode"] || "",
        opening_balance: formValues["opening_balance"] || 0.00
      };
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  */
  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    setShowDialog(false);
    //navigate("/listCustomers");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     // callUpdateApi();
     
    }
  };

  console.log("formvalues FULL : ", formValues);

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'myProfile' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'myProfile')).page_title : "My Profile"}</span>
            
            </Col>
            {/*
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
            */}
          </Row>

          
          <div className="ui-form">
            <hr align="center" />
            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                        User Name :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "5px"}}>
                          Arun
                      </Form.Label>
                       </Col>

                   <Col xs={4} md={4} sm={4} lg={4}>
                    <Form.Label style={{fontWeight: 'bold',paddingLeft: "32px"}}>
                         Display Name :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "5px"}}> 
                       Arun
                      </Form.Label>
                  </Col>

                  <Col xs={4} md={4} sm={4} lg={4}>
                    <Form.Label style={{fontWeight: 'bold',paddingLeft: "32px"}}>
                         Role :
                      </Form.Label>
                    <Form.Label style={{fontWeight: 'bold', paddingLeft: "5px"}}> 
                        Manager
                      </Form.Label>
                  </Col>
               </Row>
               <p></p>

               <h5>Change Password</h5>
                 <hr align="center" />

            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Old Password</Form.Label>
                  
                  <Form.Control
                    type="text"
                    id="old_password"
                    name="old_password"
                    //className="mobNum"
                    placeholder="Old Password"
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["old_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.old_password}</p>
              </Col>
              </Row>

              <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">New Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="new_password"
                    name="new_password"
                    //className="mobNum"
                    placeholder="New Password"
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["new_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.new_password}</p>
              </Col>
              </Row>

              <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="confirm_password"
                    name="confirm_password"
                    //className="mobNum"
                    placeholder="Confirm Password"
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["confirm_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.confirm_password}</p>
              </Col>
              </Row>
             
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
              
            
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
           
            
           {/*
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            */}
          </div>
          </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
        
      </Container>
  );
};

export default MyProfile;
