import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import { Table } from "react-bootstrap";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image,
  Pagination
} from "react-bootstrap";
//import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain.js";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config.js";
import spinner_logo from '../assets/images/spinner_logo.png';


function ListPatients() {
  const initialized = useRef(false);
  var navigate = useNavigate();
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

/*
  let patientArr = [
    { 
     reg_no: "AB001",
     name: "Varun",
     age: "26",
     mobile: "9081234567",
     ref_no: "R123",
     status: "Active"
    },
    { 
      reg_no: "AB002",
      name: "Arjun",
      age: "28",
      mobile: "894312678",
      ref_no: "R124",
      status: "Active"
     },
     { 
      reg_no: "AB003",
      name: "Harin",
      age: "25",
      mobile: "9081234567",
      ref_no: "R125",
      status: "Active"
     },
  ];
  */

  let intialValues = {
    code: "",
    name: "",
    mobile_primary: "",
    ref_no: "",
  };

  const [patientList, setPatientList] = useState([]);
  const [patientListFull, setPatientListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState( "Invalid authentication Session!");
  const [selectedId, setSelectedId] = useState(-1);
  const [showDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  var validateErr = false;

 
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

 
  

  const getPatientList = async () => {
    //var retStateLoad = false;
    //var patientRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getPatientList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setPatientList(res.data.detail.data);
        setPatientListFull(res.data.detail.data);
        setToRender(true);

        //patientRet = res.data.detail.data;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    //return [retStateLoad, patientRet];
  };

  
 

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::ListPatients called ##########################");
      getPatientList();
      /*
      (async () => {
        if (retStateLoad) {
          console.log("<<<<<<<<<<<<<<<<<< CURRENT BRANCH )))))))))))))))))) : ", currBranchRef.current);
          var [retStateLoad, patientRet] = await getPatientList();
          setToRender(true);
        }
       
      })();
      */

    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add patients called : ", e);
    navigate("/addUpdatePatient", {state: { passed_element: [], action: "add" },
  });
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
   /*
    let validName = true;
    if (!values.first_name) {
      errors.first_name = "Patient name is required";
      validateErr = true;

      validName = false;
    }

    let validNumber = true;
    if (values.mobile_number && values.mobile_number.length != 10) {
      errors.mobile_number = "Mobile number should be 10 digits";
      validateErr = true;
      validNumber = false;
      //setValidateError(true);
    }

    if (validNumber === true || validName === true) {
      validateErr = false;
      errors.mobile_number = "";
      errors.first_name = "";
    }
    */
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("handle search called : ", e);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
    }

    if (formValues.code) {
      const searchedElement = patientList.find((ele) => ele.code === formValues.code);
      console.log("searched element : ", searchedElement);
      setPatientList([searchedElement]);
    }

    if (formValues.name) {
      //const searchedElement = patientList.find((ele) => ele.name.toUpperCase() === formValues.name.toUpperCase());
      const searchedElement = patientList.find((ele) => ele.name.toUpperCase().includes(formValues.name.toUpperCase()));
      var patientListMod = [];
      patientList.forEach(function(patientInd){
      //console.log("searched element : ", searchedElement);
      if (patientInd.name.toUpperCase().includes(formValues.name.toUpperCase())){
         patientListMod.push(patientInd)
      }
     })
     console.log("FILETRED ARRAY : ", patientListMod);

     if ( patientListMod.length > 0 ) {
      setPatientList(patientListMod);
     }
    }

    if (formValues.mobile_primary) {
      const searchedElement2 = patientList.find((ele) => ele.mobile_primary === formValues.mobile_primary);
      console.log("searched element : ", searchedElement2);
      setPatientList([searchedElement2]);
    }

    if (formValues.ref_no) {
      const searchedElement2 = patientList.find((ele) => ele.ref_no === formValues.ref_no);
      console.log("searched element : ", searchedElement2);
      setPatientList([searchedElement2]);
    }
  };

  const handleClearSearch = (e) => {
    console.log("handle  clear search called : ", e);
    //setPatientList(patientsListOrig);
    //setFormValues(intialValues);
    setPatientList(patientListFull);
  };

  const handleValueChange = (e) => {
    //e.preventDefault();

    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const handleView = async (e) => {
    console.log("handle edit patients called : ", e);
    console.log("handle edit patients currentTarget called : ", e.currentTarget);
    console.log("handle edit patients target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
      var nameOfElement = selectedTagId.substring( 0, selectedTagId.lastIndexOf("-"));
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);
      console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",patientList[currentId]);
      navigate("/addUpdatePatient", {
        state: {
          passed_element: patientList[currentId],
          action: "view",
        },
      });
    }
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

  //var filename_gen = "customer";
 
  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent} />
      { toRender ?  
      <form>
          <Row>
              <Col xs={9} md={9} sm={9} lg={9}> 
                <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listPatients' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listPatients')).page_title_list : "Patients"}</span>
             </Col>

            <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
            {menuSettings.permissions.find(element => (element.code === 'listPatients' && element.a_add === true)) &&
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className="justify-content-end float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Patient
              </Button>
                }
            </Col>
          </Row>
          <hr align="center" />
          <Row>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="code"
              id="code"
              placeholder="Reg.No"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.code}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.code}</p>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.name}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.name}</p>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">
                 
            <Form.Control
            
              type="text"
              name="mobile_primary"
              //className="mobNum"
              id="mobile_primary"
              placeholder="Mobile"
              maxlength="10"
              value={formValues.mobile_primary}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>

            <p className="error-msg">{formErrors.mobile_primary}</p>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="ref_no"
              id="ref_no"
              placeholder="Ref.No"
              //onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.ref_no}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.ref_no}</p>
          </Col>

          
          <Col xs={1} md={1} sm={1} lg={1}>
            <Button
              variant="warning"
              id="btn-search-id"
              name="btn-search-name"
              type="submit"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
            <Button
              variant="secondary"
              id="btn-search-id"
              name="btn-search-name"
              onClick={handleClearSearch}
            >
              Clear Search
            </Button>
          </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>

          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Patient Reg.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Patient Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Age</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Mobile</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Ref.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Status</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
              </tr>
            </thead>
            <tbody>
              {patientList.map((patient, idx) => (
                <tr>
                  <td style={{textAlign: "right"}}>{idx + 1}</td>
                  <td style={{textAlign: "right"}}>{patient.code}</td>
                  <td style={{textAlign: "left"}}>{patient.name}</td>
                  <td style={{textAlign: "left"}}>{patient.age}</td>
                  <td style={{textAlign: "left"}}>{patient.mobile_primary}</td>
                  <td style={{textAlign: "left"}}>{patient.ref_no}</td>
                  <td style={{textAlign: "left"}}>{patient.status}</td>

                  <td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`view-btn-patient-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`view-icon-patient-name-${idx}`}
                      id={`view-icon-patient-id-${idx}`}
                      disabled={menuSettings.permissions.find(element => (element.code === 'listPatients' && element.a_detailview === true)) ? false : true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/*
          <Pagination>
          <Pagination.First />
          <Pagination.Prev />2
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item>
          <Pagination.Item active>{12}</Pagination.Item>
          <Pagination.Item >{13}</Pagination.Item>
          <Pagination.Item disabled>{14}</Pagination.Item>

          <Pagination.Ellipsis />
          <Pagination.Item>{20}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
          </Pagination>
              */}
          
          </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       </div>
       </div>
      } 
      
     </Container>
   
  );
}
export default ListPatients;
