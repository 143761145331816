export const client = {
  //domain: "https://erpapi.kmcreadymix.avinasoft.in",
  //domain: "https://api.erp.avinasoft.in",
   //domain: "https://api.office.avinasoft.in",
  //domain: "https://api.dev.vb.avinasoft.in",
  //domain: "https://api.dcl.avinasoft.in",
  domain: "https://api.dcl.bharathscans.com",
  //domain: "http://192.168.13.43:9201",
  //domain: "http://DESKTOP-H82I7IF:9601",
  //domain: "http://127.0.0.1:9601",
  //domain: "http://192.168.84.146:9501",
  //domain: "http://rockbreeze-06:9201",
  //domain: "http://antoni-pc-lenovo:9501",
  //domain: "http://192.168.60.43:9201",
  webPath: "/api/web",
  corePath: "/api/core",
  appPath: "/api/app",
  loginPath: "/",
  // signUpPath :  "/dashboard",
  // taskViewPath : "/taskView",
  // taskAddPath : "/taskAdd",
  // userViewPath : "/userView",
  // userAddPath : "/userAdd",
  // staffViewPath : "/staffView",
  // staffAddPath : "/staffAdd",
  // billedInViewPath : "/billedInView",
  // billedInAddPath : "/billedInAdd",
  // changePasswordPath : "/changePassword",
  // forgotpasswordPath : "/forgotpassword",
  headers: {
    "Content-Type": "application/json",
    //Origin: "https://api.erp.soft.in",
  },
  urlDelimiter: "#",
};

export const ConfigMinValue = 0.01;
export const configMaxValue = 10000;

export const withCredentialState = false;

export const appGlobal = {
  COUNTRY_ID : 1,
  numericRegExp : /^\d*(\.\d{0,2})?$/,
  //wholeNumberRegExp: /^(?:[1-9]\d*|\d)$/,
  wholeNumberRegExp: /^[0-9]+$|^$/,
  DEFAULT_STATE : 2,
  DEFAULT_DISTRICT: 'Tiruppur'
};

export const buildType = 'FULL';
export const COMPANY_NAME = "Bharath Scans & Lab";

//export const OS_STMT_FOR_NDAYS = 7;

export const OS_RECEIPT_FOR_NDAYS = 28;

export const OS_INVOICE_FOR_NDAYS = 30;
export const OS_BILL_FOR_NDAYS = 30;
//export const OS_INVOICE_FOR_NDAYS_END = 1;

export const MAX_QTY_LENGTH = 10;
export const MAX_AMOUNT_LENGTH = 12;

export const MAX_RESULTS_PER_PAGE = 3;
export const ADJUSTED_PAGINATION = 12;
/*
export const BANK_NAME = "HDFC BANK";
export const BANK_BRANCH = "Avinashi";
export const ACC_NUMBER = "50200042592178";
export const IFSC_CODE = "HDFC0002225";
*/

/*
export const DATABASE_NAME = "avina_billing_db";
export const COMPANY_TABLE = 'companies';
export const DB_CUSTOM_VERSION = 30;
*/
export const LS_MSG_TAG = "app_message";
