import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal, LS_MSG_TAG } from "../config/Config.js";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain.js";
import {getDistrictList, getStateList, getCustomerMessages } from "./utils.js";
import spinner_logo from '../assets/images/spinner_logo.png';



//function AddUpdateCustomer () {
const AddUpdateStudyItemCat = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  
  let initialItems = [];
  const intialValues = {
   name: "",
   description: "",
   type_id: 0,
   
  };

 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  const [submitAction, setSubmitAction] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checkState, setCheckState] =  useState(false);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const[optType, setOptType] = useState([]);
  const [typeIdSelect, setTypeIdSelect] = useState({});
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save");

  var validateErr = false;

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "age" || name === "mobile_primary" || name === "mobile_secondary")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
   
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_ITEMCAT_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }

    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };

  const CallCategoryData = async () => {
    var retStateLoad = false;
    var typeRet = [];
    
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getItemCategoryAddUpdPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.status);
        setOptType(res.data.detail.data.item_types);
        setToRender(true);

        typeRet = res.data.detail.data.item_types;
        retStateLoad = true;

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, typeRet, ];
  };

  const callCategoryDetById = async(categoryIdPassed) => {
    var retState = false;
    var categoryInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var reqParams = {
        id: categoryIdPassed
      };

    var res = await axios.post(client.domain + "/bms/getItemCategoryDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
       
        setFormValues(res.data.detail.data[0]);
        categoryInfoRet = res.data.detail.data[0];
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);   
        }
      }
      setSubmitAction(false);
    }
    return [retState, categoryInfoRet];   
  };
  
  useEffect(() => {
    if (!initialized.current) {
      console.log( "*************** useEffect::addUpdatePatient called ##########################");
      
      (async () => {
        var [retStateLoad, typeRet] = await CallCategoryData();
        
        if ( retStateLoad ) {
        if ((state.action === 'edit' || state.action === 'view')) {
          setIsReadOnly(true);
          if ( state.passed_element.status === "Active") {
            setCheckState(true);
          }
          var [retState, categoryInfoRet]  = await callCategoryDetById(state.passed_element.id);
          if (retState) {
            console.log("RETURNED CATEGORY INFO : ", categoryInfoRet);              
              var findTypeEdit = typeRet.find(element => element.value === categoryInfoRet.type_id);
              if (findTypeEdit !== undefined) {
                setTypeIdSelect(findTypeEdit);
              }
            }
          }
          else {
              var findType = typeRet.find(element => element.is_default === true);
              if (findType !== undefined) {
                setTypeIdSelect(findType);  
          }
          }
         
      }
       setToRender(true);
          
      })();

    
      if (state === null) {
        navigate("/listStudyItemCategories");
      } else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
         setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          
          setIsReadOnly(false);
        }
      }
  
      
      initialized.current = true;
    }
  }, []);

 
  const handlePostSaveOk = (e) => {
    console.log("user confirmed to customer added ...");
    setShowAlert(false);
    if( submitActionRef.current === 'save-add'){
      navigate(0);
      //navigate("/listCustomers");
      console.log("SaveOk action called for dialog confirm **************************");
    }
    else{
    console.log("Action called for dialog confirm **************************");
    navigate("/listStudyItemCategories");
    }
  };

  const handleCloseDialog = () => {
    setShowAlert(false);
    navigate("/listStudyItemsCategories");
  };

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    console.log("FORM VALUES at handleSubmit: ", formValues);
    validateErr = false;
    setFormErrors(validate(formValues));
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
    if (validateErr) {
      return -1;
    }

    var apiToCall = "";
    if (state.action === "add") {
      apiToCall = "/bms/addItemCategory";
    } else if (state.action === "view") {
      apiToCall = "/bms/updateItemCategory";
    } else {
      apiToCall = "/bms/addItemCategory";
    }
    
    var reqParam = {
      bid: currSelBusinessRef.current,
      name: formValues["name"],
      description: formValues["description"],
      type_id:typeIdSelect.value,
    };

    if ( state.action === "edit" || state.action === "view") {
      reqParam.id = formValues["id"];
      reqParam.is_active = checkState;
    } 

    
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-add') {
         setSubmitAddAction(true);
         submitActionRef.current = "save-add";
       }
       else {
         setSubmitAction(true);
         submitActionRef.current = "save";
       }
       var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
       //var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.data);
         console.log("ACTION VALUE PASSED++++++++++++++++++++++++++-------------: ", actionVal);
         if ( actionVal === 'save-add' ) {
           setSubmitAddAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertTitle("Success");
         //setShowAlert(true);
         setSubmitAction(false);
         
         if(state.action === "add"){ 
           setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_ITEMCAT_SAVED"));
         } 
        else {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_ITEMCAT_UPDATED"));
         }
         //setShowAlert(true);
 
         if ( actionVal === 'save-add' ) {
          console.log("++++++++++++++++++++++++ SAVE AND PRINT ACTION BUTTON CICKED ...");
          //setShowAlert(true);
           //navigate(0);
           setShowAlert(true);
           //navigate(0);
         }
         else {
           setShowAlert(true);
         }  
       }
       else {
        setAlertTitle("FAILED!");
         setShowAlert(true);
         setSubmitAction(false);
         
         if(state.action === 'add'){    
           setAlertBody("Study Item Category Save Failed!");
         }
         else{
           setAlertBody("Study Item Category Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       setSubmitAction(false);
     }
     
  };
  
    
 
    const handleDistrictSelect = async (e) => {
      console.log("handle district id select called : ", e);
      setDistrictIdSelect(e);
    };
   

    const handleCheckBox = async (e) => {
      console.log("handle copy to billing called :", e);
      console.log("handle copy to billing called CHECKED :", e.target.checked); 
      setCheckState(e.target.checked);   
    };

    const handleRadioChange = async (e) => {
      console.log("Value button clicked : ", e);
      const { name, id } = e.currentTarget;
      console.log("Captured value name at handleRadioChange : ", name);
      console.log("Captured value id at handleRadioChange : ", id);
      var currentRowName = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element at handleRadioChange : ", nameOfElement);
      console.log("Current row id at handleRadioChange : ", currentRowName);
      //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
      setTypeIdSelect({ value: parseInt(currentRowName), label: e.currentTarget.label })
      //setTypeIdSelect({ value: ["currentRowName"], label: e.currentTarget.label })
    };
 
  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

 

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listStudyItemCategories' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listStudyItemCategories')).page_title_addupd : "Study Item Categories"}</span>
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listStudyItemCategories' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>
          <div className="ui-form">
            <hr align="center" />
            <Row>
              
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Label>Study Type</Form.Label>
                <Form.Group>

                  {optType.map((typeInd, idx) => (
                    <>
                      <Form.Check
                        disabled={isReadOnly}
                        inline
                        label={typeInd.label}
                        name="type"
                        type={"radio"}
                        id={`radio-gender-${typeInd.value}`}
                        onChange={(e) => handleRadioChange(e)}
                        //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                        checked={typeIdSelect.value === typeInd.value ? true : false}
                      />
                    </>
                  ))}
                </Form.Group>
              </Col>
              
            <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>

              <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["description"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.description}</p>
              </Col>
              
              
              </Row>
              
            {!isReadOnly && (
              <Row>          
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  {state.action !== "add" &&
                      <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-active-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                    }
                          &nbsp;&nbsp;
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            
           
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
       
        </Form>
        )
           :
           <div className="container-spinner">
           <div className="vertical-center-spinner">
            <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
            
                   <span className="visually-hidden">Loading...</span>
                 </Spinner>
            </div>
            </div>
         }
       
      </Container>
  );
};

export default AddUpdateStudyItemCat;
